import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ProgressiveLoading from '../ProgressiveLoading';
import defaultImage from '../../assets/default-property-image.svg';
import { trackAnalytics } from '../../utils/analytics';

function PropertyFeaturedImageGrid({ property = {}, onClick }) {
  const { objects, address, agents, agency } = property;
  useEffect(() => {
    if (objects && objects.length > 0) {
      const slicedObjects = objects.slice(0, 3);
      slicedObjects.map(object => {
        agents.map(agent => {
          trackAnalytics({
            category: 'Property Image View',
            action: 'Property Page Image View',
            propertyId: property.id,
            agencyId: agency.id,
            agentId: agent.id,
            s3Key: object.s3Key,
            type: object.type,
          });
        });
      });
    }
  }, []);
  return objects && objects.length > 0 ? (
    <div
      className="property-featured-image-grid flex flex-col"
      onClick={onClick}
      aria-hidden
      key={objects[0].id}
    >
      <div className="w-full border-b border-blue-lightest">
        <img
          key={`${objects[0].url}`}
          data-src={`${objects[0].url}?auto=format`}
          src={`${objects[0].url}?fit=crop&crop=entropy&auto=format&w=500&height=250`}
          alt={address}
        />
      </div>
      {objects.length > 2 && (
        <div className="w-full flex">
          <div className="w-1/2 border-r border-blue-lightest">
            <img
              key={objects[1].s3Key}
              src={`${objects[1].url}?fit=crop&crop=entropy&px=1&auto=format&w=250&h=150`}
              data-src={`${objects[1].url}?w=250&h=150&auto=format`}
              alt={address}
            />
          </div>
          <div className="w-1/2 border-blue-lightest">
            <img
              key={objects[2].s3Key}
              src={`${objects[2].url}?fit=crop&crop=entropy&px=1&auto=format&w=250&h=150`}
              data-src={`${objects[2].url}?w=250&h=150&auto=format`}
              alt={address}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="property-featured-image-grid" onClick={onClick} aria-hidden>
      <ProgressiveLoading
        alt="property"
        src={defaultImage}
        thumb={`${defaultImage}?fit=crop&crop=entropy&px=16&auto=format`}
      />
    </div>
  );
}

PropertyFeaturedImageGrid.propTypes = {
  property: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default PropertyFeaturedImageGrid;
