import React from 'react';
import FbPageTabLayout from '../../components/FbPageTabLayout';
import AdvancedSearch from '../../components/AdvancedSearch';
import PropertyList from '../../components/PropertyList';
import LocationResolver from '../../components/LocationResolver';
import { useSearchState } from '../../contexts/search-context';

function parseLocations(search) {
  const locations = [];

  if (search.keyword) {
    locations.push(search.keyword.key);
  }

  return locations;
}

function SearchPage() {
  const search = useSearchState();

  return (
    <FbPageTabLayout agencyId={search.agencyId}>
      <AdvancedSearch />
      <LocationResolver locations={parseLocations(search)}>
        {locationsData => (
          <PropertyList
            searchOptions={search}
            locationsData={locationsData}
            agencyId={search.agencyId}
          />
        )}
      </LocationResolver>
    </FbPageTabLayout>
  );
}

export default SearchPage;
