import React from 'react';
import ReactDOM from 'react-dom';
import { MemoryRouter } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import './index.css';
import App from './components/App';
import apolloClient from './apolloClient';
import * as serviceWorker from './serviceWorker';

console.log(
  '                _ _                                           \n' +
    ' _ __ ___  __ _| | |_ _   _   ___ ___  _ __ ___    __ _ _   _ \n' +
    "| '__/ _ \\/ _` | | __| | | | / __/ _ \\| '_ ` _ \\  / _` | | | |\n" +
    '| | |  __/ (_| | | |_| |_| || (_| (_) | | | | | || (_| | |_| |\n' +
    '|_|  \\___|\\__,_|_|\\__|\\__, (_)___\\___/|_| |_| |_(_)__,_|\\__,_|\n' +
    '                      |___/                                   \n' +
    '                                                              '
);

const client = apolloClient();

const Root = () => (
  <ApolloProvider client={client}>
    <IntlProvider locale="en">
      <MemoryRouter initialEntries={[window.location]}>
        <App />
      </MemoryRouter>
    </IntlProvider>
  </ApolloProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
