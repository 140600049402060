import GoogleAnalytics from 'react-ga';

export const initializeGA = id => {
  GoogleAnalytics.initialize(id);
};

export const trackAnalytics = ({
  category = null,
  action = null,
  page = null,
  propertyId = null,
  agencyId = null,
  agentId = null,
  brokerId = null,
  s3Key = null,
  type = null,
  trackType = 'event',
}) => {
  if (propertyId) GoogleAnalytics.ga('set', 'dimension1', propertyId);
  if (agencyId) GoogleAnalytics.ga('set', 'dimension2', agencyId);
  if (agentId) GoogleAnalytics.ga('set', 'dimension3', agentId);
  if (brokerId) GoogleAnalytics.ga('set', 'dimension4', brokerId);
  if (s3Key) GoogleAnalytics.ga('set', 'dimension5', s3Key);
  if (type) GoogleAnalytics.ga('set', 'dimension9', type);
  if (trackType === 'event') {
    GoogleAnalytics.ga('send', 'event', category, action);
  } else {
    GoogleAnalytics.set({ page });
    GoogleAnalytics.ga('send', 'pageview', page);
  }
};
