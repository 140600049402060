import React from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { buildStateAddress, buildStreetAddress } from '../../utils/property';

function PropertyAddress({
  property,
  classNames,
  newLine = true,
  // className = 'w-full break-normal capitalize inline-block leading-normal truncate',
}) {
  const { isShowAddress } = property || {};
  const parseStreet = buildStreetAddress(property);
  const statePost = buildStateAddress(property);

  return (
    <div>
      <span className={`transition ${classNames.street}`}>
        {parseStreet || ''}
      </span>
      {!isShowAddress && (
        <span
          className={`${clsx('normal-case transition', classNames.street)}`}
        >
          Address available on request{' '}
        </span>
      )}
      {newLine && (parseStreet || !isShowAddress) && <br />}
      {!newLine && (parseStreet || !isShowAddress) && ', '}
      <span className={`${clsx('transition', classNames.suburb)}`}>
        {statePost || ''}
      </span>
    </div>
  );
}

PropertyAddress.defaultProps = {
  classNames: {
    main: 'text-overflow inline-block leading-normal',
    street: 'text-gray-800 font-medium',
    suburb: 'text-gray-700 font-normal',
  },
  newLine: false,
  property: {},
};

PropertyAddress.propTypes = {
  property: PropTypes.object.isRequired,
  newLine: PropTypes.bool,
  className: PropTypes.string,
};

export default PropertyAddress;
