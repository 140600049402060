import React from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

function HeaderNavigation({ history }) {
  return (
    <div className="header-navigation flex items-center">
      <Button
        type="link"
        icon={<DownOutlined />}
        size="large"
        disabled={!history.canGo(-1)}
        onClick={() => history.goBack()}
      />
      <Button
        type="link"
        icon={<RightOutlined />}
        size="large"
        disabled={!history.canGo(1)}
        onClick={() => history.goForward()}
      />
    </div>
  );
}

HeaderNavigation.propTypes = {
  history: object,
};

export default withRouter(HeaderNavigation);
