import { Component } from 'react';
import withSizes from 'react-sizes';
import PropTypes from 'prop-types';

class ScreenWidthValueProvider extends Component {
  static propTypes = {
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xxl: PropTypes.number,
  };

  static defaultProps = {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
    xxl: 0,
  };

  getTagCount = () => {
    let result = 0;
    const {
      isXs,
      isSm,
      isMd,
      isLg,
      isXl,
      isXxl,
      xs,
      sm,
      md,
      lg,
      xl,
      xxl,
    } = this.props;

    if (isXs) {
      result = xs;
    } else if (isSm) {
      result = sm;
    } else if (isMd) {
      result = md;
    } else if (isLg) {
      result = lg;
    } else if (isXl) {
      result = xl;
    } else if (isXxl) {
      result = xxl;
    }

    return result;
  };

  render() {
    return this.props.children(this.getTagCount());
  }
}

export default withSizes(({ width }) => ({
  isXs: width < 576,
  isSm: width >= 576 && width < 768,
  isMd: width >= 768 && width < 992,
  isLg: width >= 992 && width < 1200,
  isXl: width >= 1200 && width < 1600,
  isXxl: width >= 1600,
}))(ScreenWidthValueProvider);
