import React, { PureComponent } from 'react';
import { List, Tag } from 'antd';
import { gql } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import LoadingDots from '../LoadingDots';
import Error from '../Error';

class NearbySchools extends PureComponent {
  static propTypes = { data: PropTypes.object };

  static defaultProps = { data: {} };

  render() {
    const {
      data: { loading, error, property },
    } = this.props;

    if (loading) return <LoadingDots />;
    if (error) return <Error error={error} />;
    const dataSource = property ? property.nearbySchools : [];
    return (
      <List
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={({ url, name, type, distance, sector }) => (
          <List.Item className="flex sm:flex-row flex-col w-full">
            <List.Item.Meta
              title={
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="md:text-sm text-xs col-6"
                >
                  {name}
                </a>
              }
            />
            <div className="ml-2 flex w-1/2 justify-between md:flex-row flex-col items-center">
              <div>
                {type.map(level => (
                  <Tag
                    key={level}
                    className="mr-1 "
                    color={
                      level.toLowerCase() === 'primary' ? '#5aaf64' : '#108ee9'
                    }
                  >
                    {level}
                  </Tag>
                ))}
                <Tag className="mr-1 " color="grey">
                  {sector === '1' ? 'Private' : 'Government'}
                </Tag>
              </div>
              <div>
                <div>{distance.toFixed(2)} km</div>
              </div>
            </div>
          </List.Item>
        )}
      />
    );
  }
}

const PRIMARY_NEARBY_SCHOOLS_QUERY = gql`
  query property($id: ID!, $type: SchoolType) {
    property(id: $id) {
      id
      nearbySchools(type: $type) {
        id
        name
        type
        distance
        url
        state
        postCode
        contact
        sector
        status
        district
      }
    }
  }
`;

const PrimaryNearbySchoolsWithData = graphql(PRIMARY_NEARBY_SCHOOLS_QUERY, {
  options: ({ type, propertyId }) => ({
    variables: {
      type,
      id: propertyId,
    },
  }),
})(NearbySchools);

export default PrimaryNearbySchoolsWithData;
