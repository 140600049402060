import React from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import PropTypes from 'prop-types';
import FbPageTabLayout from '../../components/FbPageTabLayout';
import AgencyProfile from '../../components/AgencyProfile';
import AgencyEnquiry from '../../components/AgencyEnquiry';
import './AboutPage.scss';

function AboutPage({ location = {} }) {
  const { search } = location;
  const { agencyId: id } = qs.parse(search);
  return (
    <FbPageTabLayout>
      <div className="AboutPage">
        <AgencyProfile id={id} />
        <AgencyEnquiry id={id} />
      </div>
    </FbPageTabLayout>
  );
}

AboutPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default withRouter(AboutPage);
