import React, { Component } from 'react';
import { object, func, string } from 'prop-types';
import { EnvironmentFilled } from '@ant-design/icons';
import { Input } from 'antd';
import { loader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faGlobeAsia } from '@fortawesome/pro-regular-svg-icons/faGlobeAsia';
import Select from 'rc-select';
import 'rc-select/assets/index.css';
import './styles.scss';

const LocationSuggestionsQuery = loader('./LocationSuggestionsQuery.gql');

class PropertyLocationFilter extends Component {
  static propTypes = {
    client: object,
    value: object,
    group: string,
    onChangeLocation: func,
    onChangeGroup: func,
  };

  static defaultProps = {
    onChangeLocation: () => {},
    onChangeGroup: () => {},
  };

  state = {
    dataSource: [],
  };

  handleSearch = async value => {
    const { client } = this.props;
    const keyword = value;

    this.setState({
      isLoading: true,
      currentKeyword: value,
    });

    try {
      const {
        data: { locationSuggestions },
      } = await client.query({
        query: LocationSuggestionsQuery,
        variables: {
          filter: {
            keyword,
            first: 10,
          },
        },
      });
      this.setState({
        isLoading: false,
        dataSource: locationSuggestions || [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderOptions = (dataSource = [], keyword) => {
    if (dataSource.length === 0 && keyword) {
      return [
        <Select.Option
          key={keyword}
          className="show-all py-3"
          value={`k:${keyword}`}
          title={keyword}
        >
          <span className="text-base location-item">{keyword}</span>
        </Select.Option>,
      ];
    }
    const filteredResult = dataSource.filter(item => item !== null);

    return (
      filteredResult.length && (
        <Select.OptGroup
          key="locations"
          label={<span className="text-xs">Location</span>}
        >
          {filteredResult.map(item => (
            <Select.Option
              key={item.full}
              className="show-all py-3"
              value={item.full}
            >
              <div className="flex items-center location-item">
                <div className="mr-1">
                  {item.type === 'AREA' && <FA icon={faGlobeAsia} />}
                  {item.type === 'SUBURB' && (
                    <EnvironmentFilled className="text-xl" />
                  )}
                  {item.type === 'REGION' && <FA icon={faGlobeAsia} />}
                </div>
                <span>
                  <span className="text-base">{item.full}</span>
                  {item.type !== 'SUBURB' && (
                    <span className="ml-1 text-xs text-grey">
                      <span className="mr-1">&bull;</span>
                      {item.type}
                    </span>
                  )}
                </span>
              </div>
            </Select.Option>
          ))}
        </Select.OptGroup>
      )
    );
  };

  render = () => {
    const { value, group, onChangeLocation, onChangeGroup } = this.props;
    const { dataSource, currentKeyword } = this.state;
    return (
      <div className="flex property-location-filter">
        <Select
          className="w-1/6"
          defaultValue="BUY"
          optionLabelProp="children"
          onChange={onChangeGroup}
          value={group}
        >
          <Select.Option value="BUY">Buy</Select.Option>
          <Select.Option value="RENT">Rent</Select.Option>
        </Select>

        <Select
          className="flex-1"
          labelInValue
          placeholder="Search by suburb"
          animation="slide-up"
          allowClear
          dropdownClassName="property-search-dropdown"
          onSearch={this.handleSearch}
          filterOption={false}
          showArrow={false}
          onChange={onChangeLocation}
          value={value}
        >
          {this.renderOptions(dataSource, currentKeyword)}
        </Select>
      </div>
    );
  };
}

export default withApollo(PropertyLocationFilter);
