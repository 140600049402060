import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import withIntersectionObserver from '../WithIntersectionObserver';
import './ProgressiveLoading.scss';

// https://medium.com/front-end-hacking/progressive-image-loading-and-intersectionobserver-d0359b5d90cd
// https://medium.com/@imgix/lqip-your-images-for-fast-loading-2523d9ee4a62
class ProgressiveLoading extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    thumb: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    alt: 'image',
    className: '',
  };

  state = { ready: false };

  _mounted = false;

  componentWillMount() {
    this._mounted = true;
    const buffer = new Image();
    buffer.onload = () => this._mounted && this.setState({ ready: true });
    buffer.src = this.props.src;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const { src, thumb, alt, className } = this.props;
    const { ready } = this.state;

    return (
      <div className={`progressive-loading ${className}`}>
        <div className="progressive-loading-wrapper">
          {ready ? <img className="original z-1" alt={alt} src={src} /> : null}
          <img
            alt={alt}
            src={thumb}
            className={classNames('thumb', { hide: ready })}
          />
        </div>
      </div>
    );
  }
}

// export default withIntersectionObserver(ProgressiveLoading);
export default ProgressiveLoading;
