import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import {
  faImages,
  faThLarge,
  faVideo,
  faVolumeUp,
} from '@fortawesome/pro-regular-svg-icons';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Row, Col, Button, Divider } from 'antd';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FacebookShareButton } from 'react-share';
import tourImage from './3D.png';
import FbPageTabLayout from '../../components/FbPageTabLayout';
import SlugParser from '../../components/SlugParser';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import PropertyFeaturedImageGrid from '../../components/PropertyFeaturedImageGrid';
import PropertyAddress from '../../components/PropertyAddress';
import PropertyPrice from '../../components/PropertyPrice';
import MainFeatures from '../../components/MainFeatures';
import PropertyFeature from '../../components/PropertyFeature';
import OfiTime from '../../components/OfiTime';
import PropertyMap from '../../components/PropertyMap';
import PropertyHomeLoanCalculator from '../../components/PropertyHomeLoanCalculator';
import PropertyNearbySchoolsList from '../../components/PropertyNearbySchoolsList';
import AuctionDate from '../../components/AuctionDate';
import PropertyViewVideos from '../../components/PropertyViewVideos';
import PropertyViewPhotos from '../../components/PropertyViewPhotos';
import PropertyViewTour from '../../components/modals/PropertyViewTour';
import PropertyAudio from '../../components/modals/PropertyAudio';
import PropertyEnquiryForm from '../../components/PropertyEnquiryForm';
import AgencyLogo from '../../components/AgencyLogo';
import AgencyAgentCarousel from '../../components/AgencyAgentCarousel';
import { getColorShade } from '../../utils/colors';
import { trackAnalytics } from '../../utils/analytics';
import './PropertyPage.scss';

const PropertyQuery = loader('../../queries/PropertyQuery.graphql');

class PropertyPage extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static defaultProps = {
    data: {},
    isFormFocus: false,
  };

  state = {
    isShowViewPhotosModal: false,
    isShowViewVideosModal: false,
    isShowViewTourModal: false,
    isShowAudioModal: false,
    isStreetView: false,
    floorplan: null,
    isFormFocus: false,
    readMoreDescription: false,
    focusedAgentIndex: 0,
    descriptionHeight: 0,
    agentIndex: 0,
  };

  enquiryWrapperRef = React.createRef();

  componentDidUpdate(prevProps) {
    const {
      data: { property },
      location,
    } = this.props;
    const { descriptionHeight } = this.state;

    if (this.descriptionRef && descriptionHeight === 0) {
      this.setState({ descriptionHeight: this.descriptionRef.clientHeight });
    }

    if (prevProps.data.property !== property) {
      const { agents, agency } = property;
      agents.map(agent => {
        trackAnalytics({
          page: location.pathname,
          propertyId: property?.id,
          agencyId: agency ? agency.id : null,
          agentId: agency ? agent.id : null,
          trackType: 'pageview',
        });
      });
    }
  }

  setFocusAgent = focusedAgentIndex => {
    this.setState({
      focusedAgentIndex,
      isFormFocus: true,
    });
  };

  showViewPhotosModal = floorplan => {
    this.setState({
      isShowViewPhotosModal: true,
      floorplan,
    });
  };

  handleReadMoreDescription = readMoreDescription => {
    this.setState({ readMoreDescription });
  };

  handleMapView = isStreetView => {
    this.setState({ isStreetView });
  };

  showViewVideosModal = floorplan => {
    const {
      data: { property },
    } = this.props;
    const { agents, id, agency } = property || {};

    this.setState({
      isShowViewVideosModal: true,
      floorplan,
    });

    agents.map(agent => {
      trackAnalytics({
        category: 'Property Video View',
        action: 'Property Page Video View',
        propertyId: id,
        agencyId: agency?.id,
        agentId: agent?.id,
        type: 'VIDEO',
      });
    });
  };

  onCloseViewVideosModal = () => {
    this.setState({ isShowViewVideosModal: false });
  };

  onCloseViewTourModal = () => {
    this.setState({ isShowViewTourModal: false });
  };

  onCloseViewPhotosModal = () => {
    this.setState({ isShowViewPhotosModal: false });
  };

  onCloseAudioModal = () => {
    this.setState({ isShowAudioModal: false });
  };

  handleTierExpiration = (featuredTier, audio) => {
    if (featuredTier === 'HIGHLIGHT_199' || featuredTier === 'PREMIERE_499') {
      if (audio) {
        return true;
      }
    }

    return false;
  };

  handleClickEnquire = agent => {
    const {
      data: { property },
    } = this.props;
    const agents = property?.agents || [];
    const focusedAgentIndex = agents.findIndex(a => a.id === agent.id);
    if (focusedAgentIndex !== -1) {
      this.setFocusAgent(focusedAgentIndex);
      this.enquiryWrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    const {
      data: { property, loading, error },
    } = this.props;

    if (loading) {
      return (
        <div className="h-screen relative">
          <Loading className="center-align" />
        </div>
      );
    }

    if (error) {
      return (
        <div className="pt-12">
          <Error className="w-1/2" error={error} />
        </div>
      );
    }

    const {
      objects,
      features,
      audio,
      address,
      videoLink,
      id,
      street,
      status,
      headline,
      type,
      priceView,
      agents,
      agency,
      externalLink1,
      isUnderOffer,
      featuredTier,
      description,
      lat,
      lng,
      ofiTimes,
      auction,
      slugUrl,
    } = property || {};
    const propertyStatus = (status || 'CURRENT').toUpperCase();
    const shareLink = `${process.env.REACT_APP_PUBLIC_URL}/property/${slugUrl}`;
    const isSold = propertyStatus === 'SOLD';
    const { bannerColor } = agency || {};
    const hasFloorPlan = objects.find(
      ({ type: objType }) => objType === 'FLOORPLAN'
    );
    const isWhiteAccent = bannerColor && bannerColor.startsWith('#fff');
    const shade =
      !isWhiteAccent && bannerColor !== null && getColorShade(bannerColor)
        ? 'dark'
        : 'light';

    const {
      isShowViewVideosModal,
      isShowViewPhotosModal,
      isShowViewTourModal,
      isShowAudioModal,
      floorplan,
      readMoreDescription,
      isFormFocus,
      focusedAgentIndex,
      descriptionHeight,
    } = this.state;
    const viewAgent = agents[focusedAgentIndex];

    return (
      <div className="PropertyPage pt-8">
        <div className="mb-2 ml-2 sm:ml-0 font-semibold text-gray-600 uppercase">
          {agency?.name}
        </div>
        <div
          className="w-full h-1 bg-grey-300"
          style={{ backgroundColor: !isWhiteAccent && bannerColor }}
        />
        {isSold && (
          <div className="px-4 my-4 bg-red-700 text-red-200 text-center text-lg shadow-md z-20">
            This property is no longer available.
          </div>
        )}
        <Row type="flex" className="mt-8">
          <Col xs={24} md={14}>
            <PropertyFeaturedImageGrid
              property={property}
              onClick={() => this.showViewPhotosModal('')}
            />
            <div className="flex items-center justify-center mt-4">
              <Button.Group>
                <Button
                  className="font-bold"
                  onClick={() => this.showViewPhotosModal('')}
                  title="Images"
                >
                  <FA
                    icon={<LegacyIcon type={faImages} />}
                    className="text-lg"
                  />
                  <span className="ml-1 px-1 font-normal">
                    {objects.length > 0
                      ? `${objects.length} Images`
                      : '1 Image'}
                  </span>
                </Button>
                {hasFloorPlan && (
                  <Button
                    onClick={() => this.showViewPhotosModal('FLOORPLAN')}
                    title="Floorplan"
                  >
                    <FA
                      icon={<LegacyIcon type={faThLarge} />}
                      className="text-lg"
                    />
                    <span className="ml-1 px-1 font-medium">Floorplan</span>
                  </Button>
                )}
                {videoLink && (
                  <Button
                    title="Video"
                    className="font-bold"
                    onClick={() => this.showViewVideosModal('')}
                  >
                    <FA
                      icon={<LegacyIcon type={faVideo} />}
                      className="text-lg"
                    />
                  </Button>
                )}
                {externalLink1 && (
                  <Button
                    title="Tour"
                    className="font-bold"
                    onClick={() => this.showViewTourModal('')}
                  >
                    <img
                      src={tourImage}
                      alt="Tour"
                      style={{
                        width: 25,
                        height: 25,
                      }}
                      className="opacity-75"
                    />
                  </Button>
                )}
                {this.handleTierExpiration(featuredTier, audio) && (
                  <Button
                    title="Audio"
                    className="font-bold"
                    onClick={() => this.showAudioModal('')}
                  >
                    <FA icon={<LegacyIcon type={faVolumeUp} />} />
                  </Button>
                )}
              </Button.Group>
              <div
                className="ml-4 text-2xl text-gray-500"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <FacebookShareButton url={shareLink}>
                  <FA icon={faFacebook} />
                </FacebookShareButton>
              </div>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <div className="p-4">
              <div className="flex items-center justify-center mb-4">
                {isUnderOffer && (
                  <div className="p-1 mr-1 bg-blue-100 text-xs text-blue-800 font-semibold rounded">
                    Under Offer
                  </div>
                )}
                <h1 className="mb-0 text-2xl">
                  {isSold ? (
                    <PropertyPrice property={property} />
                  ) : (
                    priceView || <PropertyPrice property={property} />
                  )}
                </h1>
              </div>
              <MainFeatures
                features={features}
                classNames={{
                  feature: 'text-xl px-4 py-2 mx-1 text-blue-700',
                  icon: 'mr-1 text-2xl text-blue-700',
                }}
                displayText
              />
              <div className="px-2 mt-4">
                <PropertyAddress
                  property={property}
                  className="capitalize text-lg text-grey-darkest"
                />
              </div>
              <div className="px-2 mt-4">
                <AgencyLogo agency={agency} className="w-full mb-2" />
                <AgencyAgentCarousel
                  agents={agents}
                  agency={agency}
                  propertyId={property?.id}
                  onClickEnquire={this.handleClickEnquire}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row type="flex" justify="center" className="mb-12 px-4">
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col span={24} className="mt-6 lg:pr-10 sm:pr-4">
                <Row>
                  <Col xs={24}>
                    <div className="mb-8 property-content">
                      <h2 className="mt-2 mb-4 md:px-0 px-4 md:text-lg text-base">
                        {headline}
                      </h2>
                      <div
                        className={`md:text-base text-sm md:px-0 px-4 w-full property-description-wrapper ${readMoreDescription &&
                          'description-readmore'}`}
                        key={id}
                      >
                        <div
                          ref={ref => {
                            this.descriptionRef = ref;
                          }}
                          className="overflow-hidden transition property-description whitespace-pre-line text-justify break-word"
                          style={{
                            maxHeight:
                              descriptionHeight && !readMoreDescription
                                ? 400
                                : descriptionHeight || 2000,
                          }}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: description || '',
                            }}
                          />
                        </div>
                        {descriptionHeight >= 400 && (
                          <p
                            className="cursor-pointer text-blue-dark font-medium description-toggle"
                            onClick={() =>
                              this.handleReadMoreDescription(
                                !readMoreDescription
                              )
                            }
                            aria-hidden
                          >
                            <span className="bg-white block w-full">
                              {readMoreDescription ? (
                                <span className="border border-blue px-2 py-1 rounded-lg bg-white">
                                  Read less <UpOutlined />
                                </span>
                              ) : (
                                <span className="border border-blue px-2 py-1 rounded-lg bg-white">
                                  Read more <DownOutlined />
                                </span>
                              )}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              {!!agents.length && (
                <Col xs={20} md={12} className="z-20 mt-6">
                  <div className="mx-4 sm:mx-0">
                    <div
                      className="w-full rounded-lg shadow-sm"
                      style={{
                        backgroundColor:
                          (!isWhiteAccent && bannerColor) || '#F8FAFC',
                      }}
                    >
                      <div
                        className={`enquiry-form w-full p-4 ${shade}`}
                        id="enquiry-form"
                        ref={this.enquiryWrapperRef}
                      >
                        <PropertyEnquiryForm
                          propertyId={id}
                          agent={viewAgent}
                          isFormFocus={isFormFocus}
                          agency={agency}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <div className="w-full">
              <div className="w-full">
                <div
                  className="rha-area"
                  data-partner="realty.com.au"
                  data-address={address}
                  data-metrics="1|2"
                />
                <Divider className="mb-0" />
                <div className="property-map relative">
                  <PropertyMap
                    lat={lat || -25.1547443}
                    lng={lng || 134.046491}
                    isMarkerShown
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry`}
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={
                      <div
                        style={{
                          height: '300px',
                          width: '100%',
                        }}
                      />
                    }
                    mapElement={<div style={{ height: '100%' }} />}
                    handleMapView={this.handleMapView}
                    isStreetView={false}
                  />
                </div>
              </div>
              <Divider className="mt-0" />
              <div className="flex justify-center w-full">
                <div className="lg:w-full md:w-5/6 w-full">
                  <div className="mb-8 px-2">
                    <div className="mt-8 mb-6">
                      <h3 className="md:text-2xl text-xl">
                        Floorplans &amp; Interactive Tours
                      </h3>
                      {hasFloorPlan ? (
                        <Button
                          className="font-medium mt-2 text-base"
                          onClick={() => this.showViewPhotosModal('FLOORPLAN')}
                        >
                          <FA
                            icon={<LegacyIcon type={faThLarge} />}
                            className="text-lg mr-2"
                          />
                          Floorplan
                        </Button>
                      ) : (
                        <p className="my-4 text-base">Details not provided</p>
                      )}
                    </div>
                    <PropertyFeature property={property} />
                    {propertyStatus !== 'SOLD' && (
                      <>
                        <Divider className="mt-8" />
                        <div className="w-full flex justify-center">
                          <div className="xl:w-1/2 lg:w-2/3 md:w-3/4 w-5/6">
                            <OfiTime
                              ofiTime={ofiTimes}
                              street={street}
                              address={address}
                              property={property}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {auction && (
                      <>
                        <div className="w-full flex justify-center">
                          <div className="xl:w-1/2 lg:w-2/3 md:w-3/4 w-5/6">
                            <AuctionDate auctionDate={auction} />
                          </div>
                        </div>
                      </>
                    )}
                    {propertyStatus === 'CURRENT' &&
                      type &&
                      type.toUpperCase() !== 'RENTAL' && (
                        <div className="overflow-x-auto w-full">
                          <PropertyHomeLoanCalculator property={property} />
                        </div>
                      )}
                    <div className="overflow-x-auto w-full">
                      <PropertyNearbySchoolsList propertyId={id} />
                      <Divider className="mt-8" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <PropertyViewVideos
          video={videoLink}
          showModal={isShowViewVideosModal}
          onClose={this.onCloseViewVideosModal}
        />
        <PropertyViewPhotos
          property={property}
          showModal={isShowViewPhotosModal}
          floorplan={floorplan}
          onClose={this.onCloseViewPhotosModal}
        />
        {externalLink1 && (
          <PropertyViewTour
            tour={externalLink1}
            showModal={isShowViewTourModal}
            onClose={this.onCloseViewTourModal}
          />
        )}
        {this.handleTierExpiration(featuredTier, audio) && (
          <PropertyAudio
            audio={audio ? audio.url : null}
            showModal={isShowAudioModal}
            onClose={this.onCloseAudioModal}
          />
        )}
      </div>
    );
  }
}

const PropertyPageWithData = graphql(PropertyQuery, {
  options: ({ id }) => ({ variables: { id } }),
})(PropertyPage);

export default props => (
  <SlugParser {...props}>
    {id => (
      <FbPageTabLayout {...props} isPropertyPage>
        <PropertyPageWithData id={id} {...props} />
      </FbPageTabLayout>
    )}
  </SlugParser>
);
