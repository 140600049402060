/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import flowRight from 'lodash/flowRight';

const AgentContactNumberMutation = loader(
  './AgentContactNumberMutation.graphql'
);

class AgentContactNumber extends PureComponent {
  static propTypes = {
    useTel: PropTypes.bool,
    agentId: PropTypes.string,
    contactNo: PropTypes.string,
    agentContactNumberMutation: PropTypes.func,
    className: PropTypes.string,
    gaEventCategory: PropTypes.string,
    gaEventAction: PropTypes.string,
    propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    useTel: false,
    className: '',
    gaEventCategory: 'Property Enquiry',
    gaEventAction: 'Click agent contact number',
    agentContactNumberMutation: () => {},
    agentId: '',
    contactNo: '',
  };

  constructor(props) {
    super(props);

    const { contactNo = '' } = props;
    const parseNumber = contactNo
      ? contactNo.substring(0, 4) + '•'.repeat(contactNo.length - 4)
      : '';
    this.state = { isFull: false, viewNumber: parseNumber };
  }

  handleClick = () => {
    const {
      agentId,
      contactNo,
      propertyId,
      agentContactNumberMutation,
      gaEventCategory,
      gaEventAction,
    } = this.props;
    const { viewNumber } = this.state;

    if (viewNumber !== contactNo) {
      agentContactNumberMutation({
        agentId,
        propertyId,
      })
        .then(() => {
          this.setState({ viewNumber: contactNo, isFull: true });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    const { className, useTel } = this.props;
    const { viewNumber, isFull } = this.state;

    return (
      <span
        onClick={this.handleClick}
        aria-hidden
        className={`cursor-pointer ${className}`}
      >
        {isFull ? (
          <>
            {useTel ? (
              <a href={`tel:${viewNumber}`}>{viewNumber}</a>
            ) : (
              viewNumber
            )}
          </>
        ) : (
          viewNumber
        )}
      </span>
    );
  }
}

const AgentContactNumberWithMutation = flowRight(
  graphql(AgentContactNumberMutation, {
    props: ({ mutate }) => ({
      agentContactNumberMutation: input => mutate({ variables: { input } }),
    }),
  })
)(AgentContactNumber);

export default AgentContactNumberWithMutation;
