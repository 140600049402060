import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import flowRight from 'lodash/flowRight';
import { graphql } from '@apollo/client/react/hoc';
import { trackAnalytics } from '../../utils/analytics';

const AgencyContactNumberMutation = loader('./AgencyContactNumberMutation.gql');

class AgencyContactNumber extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    number: PropTypes.string,
    agencyContactNumberMutation: PropTypes.func,
    className: PropTypes.string,
    useTel: PropTypes.bool,
  };

  static defaultProps = {
    id: '',
    number: '',
    className: '',
    agencyContactNumberMutation: () => {},
    useTel: false,
  };

  constructor(props) {
    super(props);

    const { number } = this.props;
    const parseNumber = number
      ? number.substring(0, 4) + '•'.repeat(Math.abs(number.length - 4))
      : '';
    this.state = { isFull: false, viewNumber: parseNumber };
  }

  handleClick = () => {
    const { id, number, agencyContactNumberMutation } = this.props;
    const { viewNumber } = this.state;

    if (viewNumber !== number) {
      trackAnalytics({
        category: 'Property Enquiry',
        action: 'Click agency contact number',
      });

      this.setState({ viewNumber: number });

      agencyContactNumberMutation({
        agencyId: id,
        type: 'NUMBER',
      })
        .then(() => {
          this.setState({ viewNumber: number, isFull: true });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    const { className, useTel } = this.props;
    const { viewNumber, isFull } = this.state;

    return (
      <span
        onClick={this.handleClick}
        aria-hidden
        className={`cursor-pointer ${className}`}
      >
        {isFull ? (
          <>
            {useTel ? (
              <a href={`tel:${viewNumber}`}>{viewNumber}</a>
            ) : (
              viewNumber
            )}
          </>
        ) : (
          viewNumber
        )}
      </span>
    );
  }
}

const AgencyContactNumberWithMutation = flowRight(
  graphql(AgencyContactNumberMutation, {
    props: ({ mutate }) => ({
      agencyContactNumberMutation: input => mutate({ variables: { input } }),
    }),
  })
)(AgencyContactNumber);

export default AgencyContactNumberWithMutation;
