import React, { Component } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import './PropertyViewVideos.scss';

class PropertyViewVideos extends Component {
  static propTypes = {
    objects: PropTypes.array,
    onClose: PropTypes.func,
    video: PropTypes.string,
    showModal: PropTypes.bool,
  };

  static defaultProps = {
    objects: [],
    onClose: () => {},
    showModal: false,
    video: '',
  };

  handleCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  nextSlide = () => {
    this.carousel.next();
  };

  prevSlide = () => {
    this.carousel.prev();
  };

  videoLink = video => {
    let embedVideo = '';
    if (video) {
      switch (true) {
        case video.indexOf('youtube') >= 0: {
          let parseVideo =
            video.indexOf('/watch?v=') >= 0
              ? video.replace('/watch?v=', '/embed/')
              : video;
          parseVideo = parseVideo.startsWith('https')
            ? parseVideo
            : `https://${parseVideo}`;

          embedVideo = (
            <iframe
              width="100%"
              height="500px"
              src={`${parseVideo
                .replace('&feature=youtu.be', '')
                .replace('&amp;feature=youtu.be', '')}?rel=0`}
              title="Property Video"
            />
          );

          break;
        }
        case video.indexOf('youtu.be') >= 0: {
          const splitVideo = video.split('/');
          const parseVideo = `https://www.youtube.com/embed/${
            splitVideo[splitVideo.length - 1]
          }?rel=0`;

          embedVideo = (
            <iframe
              width="100%"
              height="100%"
              src={parseVideo}
              title="Property Video"
            />
          );
          break;
        }
        case video.indexOf('dropbox') >= 0: {
          let parseVideo = '';
          if (video.endsWith('dl=0')) {
            parseVideo = video.replace('dl=0', 'raw=1');
          }

          embedVideo = (
            <iframe
              width="100%"
              height="100%"
              src={parseVideo}
              title="Property Video"
            />
          );
          break;
        }
        default: {
          embedVideo = video.startsWith('https') ? (
            <iframe
              width="100%"
              height="500px"
              src={video}
              title="Property Video"
            />
          ) : (
            <a
              href={video}
              target="_blank"
              rel="noopener noreferrer"
              alt="Property Video"
              className="block center-align video-link text-xl text-grey-darker hover:text-grey-darkest bg-blue-lighter py-2 px-6 no-underline rounded montserrat"
            >
              Please click to view video
            </a>
          );
        }
      }
    }
    return embedVideo;
  };

  render() {
    const { showModal, video } = this.props;

    return (
      <Modal
        visible={showModal}
        destroyOnClose
        onCancel={this.handleCancel}
        footer={null}
        width={null}
        className="property-modal-video"
      >
        {this.videoLink(video)}
      </Modal>
    );
  }
}

export default PropertyViewVideos;
