import React from 'react';
import PropTypes from 'prop-types';
import { Background } from 'react-imgix';
import cls from 'classnames';
import './styles.scss';

const ASPECT_RATIO_1_1 = '1:1';

function BackgroundImage({
  className,
  src,
  altText,
  aspectRatio = ASPECT_RATIO_1_1,
  imgixParams,
}) {
  return (
    <div
      className={cls(
        'background-image',
        {
          [`aspect-ratio--${aspectRatio}`]:
            aspectRatio && aspectRatio !== ASPECT_RATIO_1_1,
        },
        className
      )}
      role="img"
      aria-label={altText}
    >
      <Background
        className="main-image"
        src={src}
        imgixParams={imgixParams}
        disableLibraryParam
      />
    </div>
  );
}

BackgroundImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  altText: PropTypes.string,
  aspectRatio: PropTypes.string,
  imgixParams: PropTypes.object,
};

export default BackgroundImage;
