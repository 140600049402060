import { Component } from 'react';
import PropTypes from 'prop-types';
import replace from 'lodash/replace';
import { trackAnalytics, initializeGA } from '../../utils/analytics';

if (process.env.REACT_APP_GA) {
  initializeGA(process.env.REACT_APP_GA);
}

class GoogleAnalyticsTracker extends Component {
  static propTypes = {
    location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static defaultProps = {
    location: {},
    children: {},
  };

  componentDidMount() {
    const { location } = this.props;
    if (location && location.pathname) {
      const page = location.pathname;
      this.trackPage(page);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    if (location && location.pathname) {
      const currentPage = location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        this.trackPage(nextPage);
      }
    }
  }

  trackPage = page => {
    const pageContent = page.split('/')[1];

    if (pageContent.includes(['agency', 'mortgagebroker'])) {
      const slugUrl = replace(page, `/${pageContent}/`, '');
      const pageContentId = slugUrl.split('-')[slugUrl.split('-').length - 1];

      if (pageContent === 'agency') {
        trackAnalytics({
          page,
          agencyId: pageContentId,
          trackType: 'pageview',
        });
      } else if (pageContent === 'mortgagebroker') {
        trackAnalytics({
          page,
          brokerId: pageContentId,
          trackType: 'pageview',
        });
      }
    } else {
      trackAnalytics({
        page,
        trackType: 'pageview',
      });
    }
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

export default GoogleAnalyticsTracker;
