import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function AgencyLogo({ agency, className }) {
  return (
    <Link to={`/about/?agencyId=${agency.id}`}>
      <img
        className={className}
        src={
          agency.miniLogoUrl
            ? `${agency.miniLogoUrl}?w=300&h=75`
            : `${agency.logoUrl}?w=300&h=75`
        }
        alt={agency.name}
      />
    </Link>
  );
}

AgencyLogo.propTypes = {
  agency: PropTypes.object,
  className: PropTypes.string,
};

function isSameAgencyProps({ agency: prevAgency }, { agency: nextAgency }) {
  return (
    prevAgency.logoUrl === nextAgency.logoUrl &&
    prevAgency.miniLogoUrl === nextAgency.miniLogoUrl
  );
}

export default React.memo(AgencyLogo, isSameAgencyProps);
