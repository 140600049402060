import React from 'react';
import PropertyLocationFilter from '../PropertyLocationFilter';
import {
  useSearchState,
  useSearchUpdater,
  SEARCH_UPDATE,
} from '../../contexts/search-context';

function getLocationFormValue(search) {
  return search.keyword ? search.keyword : undefined;
}

function PropertyLocationSelectHeader() {
  const search = useSearchState();
  const setSearch = useSearchUpdater();

  const handleChangeLocation = keyword => {
    setSearch({ type: SEARCH_UPDATE, keyword });
  };

  const handleChangeGroup = group => {
    setSearch({
      type: SEARCH_UPDATE,
      group,
      price: { min: undefined, max: undefined },
    });
  };

  return (
    <div className="property-location-select-header">
      <PropertyLocationFilter
        onChangeLocation={handleChangeLocation}
        onChangeGroup={handleChangeGroup}
        value={getLocationFormValue(search)}
        group={search.group}
      />
    </div>
  );
}

export default PropertyLocationSelectHeader;
