import React, { useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import { loader } from 'graphql.macro';
import Scrollchor from 'react-scrollchor';
import {
  EnvironmentOutlined,
  ShopOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Col, Divider, Row, Carousel } from 'antd';
import {
  faEnvelope,
  faLink,
  faPhone,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { CarouselNextArrow, CarouselPrevArrow } from '../Buttons';
import AgencyContactModal from '../modals/AgencyContactModal';
import coverPhoto from './cover.png';

const AgencyQuery = loader('./AgencyQuery.gql');

function AgencyProfile(props) {
  const [showContactModal, setIsShowContactModal] = useState(false);

  const handleContactModal = () => {
    setIsShowContactModal(!showContactModal);
  };

  const {
    data: { agency, loading, error },
  } = props;

  const {
    url,
    email,
    name,
    logoUrl,
    coverPhotoUrl,
    contactNo,
    suburb,
    state,
    street,
    postalCode,
    landlineNumber,
    officeEmail,
    aboutUs,
    agents,
  } = agency || {};

  const chunkedAgents = chunk(agents, 10);
  const newContactNumber = landlineNumber || contactNo;
  const newEnquireEmail = officeEmail || email;
  return (
    <>
      <AgencyContactModal
        visible={showContactModal}
        agency={agency}
        onOk={handleContactModal}
      />
      <Row
        className="bg-grey-lighter p-4 agency-banner md:flex-row flex-col items-center"
        style={{ backgroundImage: `url(${coverPhotoUrl || coverPhoto})` }}
        type="flex"
        justify="center"
        align="middle"
        id="about"
      >
        <Col xs={24} md={22} xl={20} className="z-10">
          <div className="text-center h-64 relative">
            <div className="text-center my-8 center-align w-full ">
              <Avatar
                alt={`${name}`}
                size={150}
                icon={<ShopOutlined />}
                src={logoUrl}
                className="shadow border-4 border-white "
              />
            </div>
          </div>
          <div>
            <h1 className="agency-name text-white md:text-xl sm:text-lg text-md text-shadow text-center">
              {name}
            </h1>
            <Divider className="bg-grey my-2" />
            <Row
              type="flex"
              className="lg:flex-row items-center actions md:justify-between justify-center"
            >
              {street && (
                <Col>
                  <a
                    href={`https://maps.google.com/?q=${street} ${suburb} ${state}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h3 className="text-white text-shadow-sm capitalize">
                      <EnvironmentOutlined />{' '}
                      {street ? street.toLowerCase() : ''}{' '}
                      {suburb ? suburb.toLowerCase() : ''} {state} {postalCode}
                    </h3>
                  </a>
                </Col>
              )}
              <Col className="lg:text-right text-center">
                <ul className="inline-block list-reset">
                  <li>
                    <div className="text-center my-2 md:mx-4 mx-2 font-bold">
                      <span className="text-white text-shadow-sm">
                        <Scrollchor
                          to="#enquiry"
                          animate={{ offset: -95 }}
                          afterAnimate={() =>
                            document.getElementById('name') &&
                            document.getElementById('name').focus()
                          }
                          className="inline-block text-white"
                        >
                          <FA size="lg" icon={faEnvelope} />
                          <p className="md:text-sm text-xs">Enquire</p>
                        </Scrollchor>
                      </span>
                    </div>
                  </li>
                  {newContactNumber && newEnquireEmail && (
                    <li>
                      <div
                        className="text-center my-2 md:mx-4 mx-2 font-bold cursor-pointer text-white text-shadow-sm"
                        onClick={handleContactModal}
                        aria-hidden
                      >
                        <div>
                          <FA size="lg" icon={faPhone} />
                        </div>
                        <p className="md:text-sm text-xs">Contacts</p>
                      </div>
                    </li>
                  )}
                  {url && (
                    <li>
                      <div className="text-center my-2 md:mx-4 mx-2 font-bold">
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-white text-shadow-sm"
                        >
                          <div>
                            <FA size="lg" icon={faLink} />
                          </div>
                          <p className="md:text-sm text-xs">Website</p>
                        </a>
                      </div>
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <div className="m-6 text-center text-base">
        {aboutUs ? <p>{aboutUs}</p> : ''}
      </div>

      {agents && (
        <Row className="my-8" type="flex" justify="center" id="agents">
          <Col className="text-center py-4 md:px-0 px-2" xs={24}>
            <h2 id="team" className="md:text-2xl sm:text-xl text-lg">
              {name} Team
            </h2>
          </Col>
          <Col xs={24} md={24} xl={22} className="md:pt-8 pt-4">
            <Carousel
              dots={false}
              swipeToSlide
              arrows
              prevArrow={<CarouselPrevArrow />}
            >
              {chunkedAgents.map((chunks, index) => (
                <div key={`${index}-chunk`}>
                  <div className="flex w-full flex-wrap justify-center">
                    {chunks.map(
                      ({
                        id,
                        firstName,
                        lastName,
                        profilePicture,
                        position,
                      }) => (
                        <div
                          className="xxl:w-1/5 xl:w-1/4 lg:w-1/4 md:w-1/3 sm:w-1/2 w-full inline-block p-4"
                          key={id}
                        >
                          <div className="text-center">
                            <Avatar
                              className="bg-grey-lightest my-2 h-20 w-20 shadow agent-avatar"
                              src={profilePicture}
                              size={60}
                              icon={<UserOutlined />}
                            />
                          </div>
                          <div className="md:pl-0 pl-1">
                            <h3 className="text-center text-gray-600 font-normal">
                              {firstName} {lastName}
                            </h3>
                            <p className="text-center font-normal text-gray-500 uppercase text-xs">
                              {position}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      )}
    </>
  );
}

AgencyProfile.propTypes = {
  data: PropTypes.shape({
    agency: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }),
};

const AgencyProfileWithData = graphql(AgencyQuery, {
  options: ({ id }) => ({ variables: { id } }),
})(AgencyProfile);

export default AgencyProfileWithData;
