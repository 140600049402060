import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import MainMenu from '../MainMenu';
import HeaderNavigation from '../HeaderNavigation';
import PropertyLocationSelectHeader from '../PropertyLocationSelectHeader';
import AgencyLogoHeader from '../AgencyLogoHeader';
import AgencyLocationFilter from '../AgencyLocationFilter';
import Footer from '../Footer';

function FbPageTabLayout(props) {
  const { agencyId, children, isPropertyPage } = props;

  return (
    <div className="fb-custom-tab-container">
      <div className="flex items-center">
        <MainMenu />
      </div>
      <div className="header flex items-center pr-2">
        <HeaderNavigation />
        {agencyId ? (
          <>
            <AgencyLocationFilter agencyId={agencyId} isPropertyPage={isPropertyPage}/>
            <div className="ml-4 hidden sm:block">
              <AgencyLogoHeader id={agencyId} />
            </div>
          </>
        ) : (
          <div className="w-full sm:w-1/2">
            <PropertyLocationSelectHeader />
          </div>
        )}
      </div>
      {/*{agencyId && (<AgencyLocationFilter*/}
        {/*agencyId={agencyId}*/}
        {/*buttonsOnly*/}
      {/*/>)*/}
      {/*}*/}
      {children}
      <Footer />
    </div>
  );
}

FbPageTabLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FbPageTabLayout;
