/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import {
  formatPrice,
  formatPriceWithTwoDecimalPoints,
} from '../../utils/number';

const PropertyPrice = ({
  currencyClassName,
  className,
  price,
  showDecimal,
  isSold,
}) =>
  !isSold ? (
    <span>
      <span
        className={currencyClassName || 'text-sm'}
        style={{ marginRight: 2 }}
      >
        <Tooltip title="Australian Dollar">$</Tooltip>
      </span>
      <span className={className || 'text-2xl tracking-tighter font-hairline'}>
        {showDecimal
          ? formatPriceWithTwoDecimalPoints(price)
          : formatPrice(price)}
      </span>
    </span>
  ) : (
    'Sold'
  );

PropertyPrice.propTypes = {
  className: PropTypes.string,
  currencyClassName: PropTypes.string,
  isSold: PropTypes.bool,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showDecimal: PropTypes.bool,
};

PropertyPrice.defaultProps = {
  className: '',
  currencyClassName: '',
  isSold: false,
  price: 0,
  showDecimal: true,
};

export default PropertyPrice;
