import React, { PureComponent } from 'react';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import Download from '@axetroy/react-download';
import isEmpty from 'lodash/isEmpty';

class AuctionDate extends PureComponent {
  static propTypes = {
    address: PropTypes.string,
    auctionDate: PropTypes.shape({ auction: PropTypes.string }),
    street: PropTypes.string,
  };

  static defaultProps = {
    address: '',
    auctionDate: { auction: '' },
    street: '',
  };

  snakeCase = text =>
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '_') // Replace spaces with _
      /* eslint-disable no-useless-escape */
      .replace(/[^\w\-]+/g, '_') // Replace _ all non-word chars
      /* eslint-disable no-useless-escape */
      .replace(/\_\_+/g, '_') // Replace multiple _ with single -
      .replace(/^_+/, '') // Trim _ from start of text
      .replace(/_+$/, ''); // Trim _ from end of text

  render() {
    const { auctionDate, street, address } = this.props;
    const { auction } = auctionDate || {};

    const auctionDateTime = street
      ? `${this.snakeCase(street)}.ics`
      : `${this.snakeCase(address)}.ics`;

    return (
      auction && (
        <div className="property-time my-2 xs:p-2 shadow rounded-lg w-full">
          <div className="bg-grey-lighter rounded-lg">
            <span className="uppercase text-grey-darkest font-bold text-center block p-3">
              auction
            </span>
            {!isEmpty(auctionDate) ? (
              <>
                <Row
                  className="w-2/4 p-4"
                  type="flex"
                  justify="center"
                  align="middle"
                >
                  <Col xs={1}>
                    <h4 className="uppercase text-center font-light text-grey-darker">
                      {moment.utc(auction).format('ddd')}
                    </h4>
                    <h2 className="text-center text-blue-dark leading-tight">
                      {moment.utc(auction).format('D')}
                    </h2>
                    <h4 className="uppercase text-center font-light text-grey-darker">
                      {moment.utc(auction).format('MMM')}
                    </h4>
                  </Col>
                  <Col xs={18}>
                    <div className="text-center text-lg">
                      {moment.utc(auction).format('h:mm a')}
                    </div>
                  </Col>
                  <Col>
                    <Download
                      file={auctionDateTime || 'auctionDate.ics'}
                      content={auctionDate.icsData}
                    >
                      <Tooltip title="Save to calendar">
                        <CalendarOutlined
                          style={{ cursor: 'pointer' }}
                          className="hover:text-blue-dark text-2xl"
                        />
                      </Tooltip>
                    </Download>
                  </Col>
                </Row>
              </>
            ) : (
              <p className="text-center">
                <span className="block">
                  No auctions are currently scheduled.
                </span>
              </p>
            )}
          </div>
        </div>
      )
    );
  }
}

export default AuctionDate;
