import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PhoneOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import PropertyEnquiryForm from '../../PropertyEnquiryForm';

export default class PropertyEnquiryModal extends PureComponent {
  static propTypes = {
    agent: PropTypes.object,
    agency: PropTypes.object,
    onClose: PropTypes.func,
    propertyId: PropTypes.string,
    visible: PropTypes.bool,
  };

  static defaultProps = {
    agent: {},
    agency: {},
    onClose: () => {},
    propertyId: '',
    visible: false,
  };

  render() {
    const { propertyId, agent, onClose, visible, agency } = this.props;
    return (
      <Modal
        centered
        footer={null}
        onCancel={onClose}
        visible={visible}
        title={
          <span className="poppins">
            <PhoneOutlined className="mr-2" />
            Send&nbsp;
            {agent.firstName}
            &nbsp;a Message
          </span>
        }
      >
        <PropertyEnquiryForm
          propertyId={propertyId}
          agent={agent}
          agency={agency}
        />
        <div className="block text-center">
          <a href={`tel:${agent.contactNo}`}>
            or give {agent.firstName} a call{' '}
          </a>
        </div>
      </Modal>
    );
  }
}
