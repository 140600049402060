import React, { Component } from 'react';
import { InputNumber } from 'antd';
import PropTypes from 'prop-types';

class PriceInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = { onChange: () => {} };

  handleChange = changedValue => {
    if (isNaN(changedValue)) {
      return;
    }
    const { onChange } = this.props;
    onChange(changedValue);
  };

  render() {
    return (
      <span>
        <InputNumber
          {...this.props}
          className="w-full"
          onChange={this.handleChange}
          type="text"
          formatter={value =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      </span>
    );
  }
}

export default PriceInput;
