/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faBath,
  faBed,
  faCar,
  faCouch,
  faBox,
  faToilet,
  faRulerCombined,
  faCabinetFiling,
  faSpa,
  faBurn,
  faSnowflake,
  faTv,
  faBooks,
  faCarGarage,
  faSwimmingPool,
  faContainerStorage,
  faDumbbell,
  faTennisBall,
  faFireSmoke,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

class PropertyFeature extends Component {
  static propTypes = { property: PropTypes.object };

  static defaultProps = { property: {} };

  state = {
    indoor: '',
    outdoor: '',
    general: '',
  };

  componentDidMount = () => {
    const { indoor } = this.state;
    const general = this.countChildren(this.general);
    const indoorDisp = this.countChildren(this.indoor);
    const outdoor = this.countChildren(this.outdoor);

    if (!indoor) {
      this.setState({
        general,
        outdoor,
        indoor: indoorDisp,
      });
    }
  };

  countChildren = parent =>
    parent &&
    !!parent.props &&
    !!parent.props.children &&
    parent.props.children.filter(v => v).length > 0
      ? 'block'
      : 'hidden';

  featuresMake = (feature, icons, text) => {
    const { property } = this.props;
    const { landAreaUnit } = property;

    return (
      (!!feature || (!feature && text === 'Land Area')) && (
        <Col xs={12} sm={10} className="text-base my-2">
          <span className="w-4 mr-2 inline-block">
            {icons.map((icon, index) => {
              const i = index * 2;
              return <FA icon={icon} key={`${text} - ${i}`} />;
            })}
          </span>
          <span>
            {text}:
            <strong>
              {' '}
              {typeof feature === 'boolean' ? (
                <FA icon={faCheck} className="ml-2" />
              ) : (
                feature
              )}{' '}
            </strong>
            {text === 'Land Area' && feature ? (
              <>
                {landAreaUnit === 'squareMeter' ? (
                  <>
                    m<sup>2</sup>
                  </>
                ) : (
                  landAreaUnit
                )}
              </>
            ) : (
              ''
            )}
          </span>
        </Col>
      )
    );
  };

  featuresRaw = (text, feature) =>
    !!feature && (
      <Col xs={12} sm={8} className="text-base my-2">
        <>
          {text}:
          <strong>
            {' '}
            {typeof feature === 'boolean' ? (
              <FA icon={faCheck} className="ml-2" />
            ) : (
              feature
            )}
          </strong>
        </>
      </Col>
    );

  render() {
    const {
      property: { features, landArea },
    } = this.props;
    const { general, indoor, outdoor } = this.state;

    const {
      bathrooms,
      toilet,
      bedrooms,
      ensuite,
      livingArea,
      garages,
      carports,
      remoteGarage,
      secureParking,
      study,
      dishwasher,
      builtInRobes,
      gym,
      workshop,
      rumpusRoom,
      floorboards,
      broadband,
      payTv,
      ductedHeating,
      ductedCooling,
      splitsystemHeating,
      hydronicHeating,
      splitsystemAircon,
      gasHeating,
      reverseCycleAircon,
      evaporativeCooling,
      airConditioning,
      alarmSystem,
      vacuumSystem,
      intercom,
      poolInGround,
      poolAboveGround,
      tennisCourt,
      balcony,
      deck,
      courtyard,
      outdoorEnt,
      shed,
      fullyFenced,
      openFirePlace,
      openSpaceshotWaterService,
      insideSpa,
      outsideSpa,
      solarPanels,
      solarHotWater,
      waterTank,
      greyWaterSystem,
      otherFeatures,
    } = features || [];

    return (
      <div className="propertyfeature">
        <div className={`general ${general}`}>
          <h3 className="text-xl leading-loose">General Features</h3>
          <Row
            type="flex"
            ref={ref => {
              this.general = ref;
            }}
          >
            {this.featuresMake(bathrooms, [faBath], 'Bathrooms')}
            {this.featuresMake(bedrooms, [faBed], 'Bedrooms')}
            {this.featuresMake(livingArea, [faCouch], 'Living Areas')}
            {this.featuresMake(toilet, [faToilet], 'Toilets')}
            {this.featuresMake(landArea, [faRulerCombined], 'Land Area')}
            {this.featuresMake(
              (carports || 0) + (garages || 0),
              [faCar],
              'Car Spaces'
            )}
            {this.featuresMake(ensuite, [faBath], 'Ensuites')}
            {this.featuresMake(balcony, [faBox], 'Balcony')}
          </Row>
        </div>
        <div className={`indoor ${indoor} mt-3`}>
          <h3 className="text-xl leading-loose">Indoor Features</h3>
          <Row
            type="flex"
            ref={ref => {
              this.indoor = ref;
            }}
          >
            {this.featuresMake(
              airConditioning,
              [faSnowflake],
              'Air Conditioning'
            )}
            {this.featuresMake(
              builtInRobes,
              [faCabinetFiling],
              'Built in Robes'
            )}
            {this.featuresMake(gym, [faDumbbell], 'Gym')}
            {this.featuresMake(payTv, [faTv], 'Pay TV')}
            {this.featuresMake(insideSpa, [faSpa], 'Inside Spa')}
            {this.featuresMake(gasHeating, [faBurn], 'Gas Heating')}
            {this.featuresMake(study, [faBooks], 'Study')}
            <Col xs={24} className="h-4" />
            {this.featuresRaw('Dishwasher', dishwasher)}
            {this.featuresRaw('Workshop', workshop)}
            {this.featuresRaw('Rumpus Room', rumpusRoom)}
            {this.featuresRaw('FloorBoards', floorboards)}
            {this.featuresRaw('Broadband', broadband)}
            {this.featuresRaw('Ducted Heating', ductedHeating)}
            {this.featuresRaw('Ducted Cooling', ductedCooling)}
            {this.featuresRaw('Split System Heating', splitsystemHeating)}
            {this.featuresRaw('Hydronic Heating', hydronicHeating)}
            {this.featuresRaw('Split System Aircon', splitsystemAircon)}
            {this.featuresRaw('Reverse Cycle Aircon', reverseCycleAircon)}
            {this.featuresRaw('Evaporative Cooling', evaporativeCooling)}
            {this.featuresRaw('Alarm System', alarmSystem)}
            {this.featuresRaw('Vacuum System', vacuumSystem)}
            {this.featuresRaw('Intercom', intercom)}
          </Row>
        </div>
        <div className={`outdoor ${outdoor} mt-3`}>
          <h3 className="text-xl leading-loose">Outdoor Features</h3>
          <Row
            type="flex"
            ref={ref => {
              this.outdoor = ref;
            }}
          >
            {this.featuresMake(garages, [faCarGarage], 'Garages')}
            {this.featuresMake(
              poolInGround,
              [faSwimmingPool],
              'Swimming Pool - Inground'
            )}
            {this.featuresMake(
              poolAboveGround,
              [faSwimmingPool],
              'Swimming Pool - Above Ground'
            )}
            {this.featuresMake(
              fullyFenced,
              [faContainerStorage],
              'Fully Fenced'
            )}
            {this.featuresMake(openFirePlace, [faFireSmoke], 'Open Fire Place')}
            {this.featuresMake(tennisCourt, [faTennisBall], 'Tennis Court')}
            <Col xs={24} className="h-4" />
            {this.featuresRaw('Carports', carports)}
            {this.featuresRaw('Secure Parkings', secureParking)}
            {this.featuresRaw('Remote Garages', remoteGarage)}
            {this.featuresRaw('Deck', deck)}
            {this.featuresRaw('Courtyard', courtyard)}
            {this.featuresRaw('Outdoor Entertainment', outdoorEnt)}
            {this.featuresRaw('Shed', shed)}
            {this.featuresRaw('Outside Spa', outsideSpa)}
            {this.featuresRaw(
              'Open Space Hot Water Service',
              openSpaceshotWaterService
            )}
            {this.featuresRaw('Solar Panels', solarPanels)}
            {this.featuresRaw('Solar Hot Water', solarHotWater)}
            {this.featuresRaw('Water Tank', waterTank)}
            {this.featuresRaw('Grey Water System', greyWaterSystem)}
          </Row>
        </div>
        {otherFeatures && otherFeatures !== '0' && (
          <div className="others mt-3">
            <h3 className="text-xl leading-loose">Other Features</h3>
            <Row type="flex">
              {otherFeatures.split(/,/g).map(item => (
                <Col xs={12} sm={8} key={item} className="my-2">
                  {item}
                  <br />
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default PropertyFeature;
