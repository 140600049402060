import React, { useEffect, useReducer } from 'react';
import { DoubleLeftOutlined } from '@ant-design/icons';
import { Avatar, Col, Input, Progress, Row, Button } from 'antd';
import { useApolloClient } from '@apollo/client';
import { Link } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faHandHoldingUsd,
  faShoppingCart,
  faLandmark,
  faMountain,
  faSackDollar,
  faHome,
} from '@fortawesome/pro-regular-svg-icons';
import './styles.scss';
import lizel from '../../assets/lizel.jpg';
import irish from '../../assets/irish.jpg';
import troy from '../../assets/troy.jpg';
import PropertySuburbSelect from '../../components/PropertySuburbSelect';
import ContactAgentInformationForm from '../../components/ContactAgentInformationForm';
import LoadingDots from '../../components/LoadingDots';
import Error from '../../components/Error';

const AgentsCountByPropertySearch = loader('./AgentsCountByPropertySearch.gql');

function reducer(state, action) {
  switch (action.type) {
    case 'SET_STEP_1':
      return {
        ...state,
        type: action.value,
        step: 2,
      };
    case 'SET_STEP_2':
      return {
        ...state,
        status: action.value,
        step: 'SUBURB_SELECT',
      };
    case 'SET_STREET_NUMBER':
      return {
        ...state,
        streetNumber: action.value,
      };
    case 'SET_STREET':
      return {
        ...state,
        street: action.value,
      };
    case 'SET_LOCATION':
      return {
        ...state,
        locationValue: action.value,
        step: 'BEDROOM_SELECT',
      };
    case 'SET_BEDROOM':
      return {
        ...state,
        bedroom: action.value,
        step: 3,
      };
    case 'SET_PRICE_RANGE':
      return {
        ...state,
        priceRange: action.value,
        step: 4,
      };
    case 'SET_DESCRIPTION':
      return {
        ...state,
        isCountingAgents: true,
        goal: action.value,
      };
    case 'SET_AGENT_COUNT':
      return {
        ...state,
        isCountingAgents: false,
        agentCount: action.agentCount,
        agentId: action.agentId,
        step: 5,
      };
    case 'SET_SUBMITTED':
      return {
        ...state,
        submit: action.value,
      };
    default:
      throw new Error('Unknown action');
  }
}

function ContactAgentPage() {
  const initialState = {
    type: null,
    status: null,
    priceRange: null,
    step: 1,
    isCountingAgents: false,
    agentCount: null,
    bedroom: null,
    locationValue: [],
    agentId: null,
    goal: null,
    street: null,
    streetNumber: null,
    submit: false,
  };

  const [form, dispatch] = useReducer(reducer, initialState);

  const handleStreetNumber = ({ target: { value } }) => {
    dispatch({ type: 'SET_STREET_NUMBER', value });
  };

  const handleStreet = ({ target: { value } }) => {
    dispatch({ type: 'SET_STREET', value });
  };

  const handleLocationSelect = value => {
    dispatch({ type: 'SET_LOCATION', value });
  };

  const handleSubmit = value => {
    dispatch({ type: 'SET_SUBMITTED', value });
  };

  const client = useApolloClient();

  useEffect(() => {
    async function fetchData() {
      try {
        if (form.isCountingAgents) {
          const {
            data: { agentsCountByPropertySearch },
          } = await client.query({
            query: AgentsCountByPropertySearch,
            variables: {
              filter: {
                group: form.type,
                category: form.status,
                price: form.priceRange,
                locationId: form.locationValue.length
                  ? form.locationValue[0].id
                  : null,
              },
            },
          });

          dispatch({
            type: 'SET_AGENT_COUNT',
            agentCount: agentsCountByPropertySearch ? 1 : 0,
            agentId: agentsCountByPropertySearch
              ? agentsCountByPropertySearch.id
              : null,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [form.isCountingAgents]);

  const group =
    form.type === 'Rent as tenant' || form.type === 'Rent as landlord'
      ? 'RENT'
      : 'BUY';

  const computeProgress = () => {
    let percent = 0;
    switch (form.step) {
      case 1:
        percent = 20;
        break;
      case 2:
        percent = 40;
        break;
      case 'SUBURB_SELECT':
        percent = 50;
        break;
      case 'BEDROOM_SELECT':
        percent = 55;
        break;
      case 3:
        percent = 60;
        break;
      case 4:
        percent = 80;
        break;
      case 5:
        percent = 90;
        break;
      case 6:
        percent = 100;
        break;
      default:
        break;
    }
    return percent;
  };

  return (
    <div className="contact-agent-page flex-1">
      <div className="w-full h-full flex justify-center items-center bg-blue-900">
        <Row align="middle" justify="center" type="flex" className="w-full">
          <Col span={24}>
            {form.step === 1 && (
              <div className="text-center">
                <h1 className="step-title">
                  What can a top agent help you achieve?
                </h1>
                <div className="flex justify-center flex-wrap">
                  <button
                    className="box text-center shadow-md"
                    type="button"
                    onClick={() =>
                      dispatch({ type: 'SET_STEP_1', value: 'Sell' })
                    }
                  >
                    <FA
                      icon={faHandHoldingUsd}
                      className="text-6xl my-4 text-blue-500"
                    />
                    <div className="font-normal text-md">Seller</div>
                  </button>
                  <button
                    className="box text-center shadow-md"
                    type="button"
                    onClick={() =>
                      dispatch({ type: 'SET_STEP_1', value: 'BUY' })
                    }
                  >
                    <FA
                      icon={faShoppingCart}
                      className="text-6xl my-4 text-blue-500"
                    />
                    <div className="font-normal text-md">Buyer</div>
                  </button>
                  <button
                    className="box text-center shadow-md"
                    type="button"
                    onClick={() =>
                      dispatch({
                        type: 'SET_STEP_1',
                        value: 'Rent as landlord',
                      })
                    }
                  >
                    <FA
                      icon={faSackDollar}
                      className="text-6xl my-4 text-blue-500"
                    />
                    <div className="font-normal text-md">Rent as landlord</div>
                  </button>
                  <button
                    className="box text-center shadow-md"
                    type="button"
                    onClick={() =>
                      dispatch({ type: 'SET_STEP_1', value: 'Rent as tenant' })
                    }
                  >
                    <FA
                      icon={faSackDollar}
                      className="text-6xl my-4 text-blue-500"
                    />
                    <div className="font-normal text-md">Rent as tenant</div>
                  </button>
                </div>
              </div>
            )}
            {form.step === 2 && (
              <div className="text-center">
                <h1 className="step-title">
                  What type of property are you looking to {form.type}?
                </h1>
                <p className="mb-8 text-white p-2">
                  Let’s find an agent with the best track record for your
                  property type.
                </p>
                <div className="flex justify-center flex-wrap">
                  <button
                    className="box text-center shadow-md"
                    type="button"
                    onClick={() =>
                      dispatch({ type: 'SET_STEP_2', value: 'House' })
                    }
                  >
                    <FA icon={faHome} className="text-6xl my-4 text-blue-500" />
                    <div className="font-normal text-md">Home</div>
                  </button>
                  <button
                    className="box text-center shadow-md"
                    type="button"
                    onClick={() =>
                      dispatch({ type: 'SET_STEP_2', value: 'Building' })
                    }
                  >
                    <FA
                      icon={faLandmark}
                      className="text-6xl my-4 text-blue-500"
                    />
                    <div className="font-normal text-md">Building</div>
                  </button>
                  <button
                    className="box text-center shadow-md"
                    type="button"
                    onClick={() =>
                      dispatch({ type: 'SET_STEP_2', value: 'Land' })
                    }
                  >
                    <FA
                      icon={faMountain}
                      className="text-6xl my-4 text-blue-500"
                    />
                    <div className="font-normal text-md">Land</div>
                  </button>
                </div>
              </div>
            )}
            {form.step === 'SUBURB_SELECT' && (
              <div className="text-center">
                <h1 className="step-title">Where is your property located?</h1>
                <p className="mb-8 text-white p-2">
                  Let&apos;s find an agent with the best track record in your
                  area.
                </p>
                <div className="flex justify-center flex-wrap">
                  <div className="w-full sm:w-1/2 mx-4">
                    {form.type === 'Sell' && (
                      <div className="flex">
                        <Input
                          onChange={handleStreetNumber}
                          placeholder="Street #"
                          size="large"
                          style={{ marginRight: 20, width: 100 }}
                        />
                        <Input
                          onChange={handleStreet}
                          placeholder="Street"
                          size="large"
                          style={{ marginBottom: 20 }}
                        />
                      </div>
                    )}
                    <PropertySuburbSelect
                      disabled
                      onChange={handleLocationSelect}
                      value={form.locationValue}
                    />
                  </div>
                </div>
              </div>
            )}
            {form.step === 'BEDROOM_SELECT' && (
              <div className="text-center">
                <h1 className="step-title">
                  How many bedrooms does your property have?
                </h1>
                <div className="flex justify-center flex-wrap">
                  <div>
                    <button
                      className="rectangle text-center shadow-md flex items-center justify-center"
                      type="button"
                      onClick={() =>
                        dispatch({ type: 'SET_BEDROOM', value: 1 })
                      }
                    >
                      <div className="font-normal text-2xl">1</div>
                    </button>
                    <button
                      className="rectangle text-center shadow-md flex items-center justify-center"
                      type="button"
                      onClick={() =>
                        dispatch({ type: 'SET_BEDROOM', value: 2 })
                      }
                    >
                      <div className="font-normal text-2xl">2</div>
                    </button>
                    <button
                      className="rectangle text-center shadow-md flex items-center justify-center"
                      type="button"
                      onClick={() =>
                        dispatch({ type: 'SET_BEDROOM', value: 3 })
                      }
                    >
                      <div className="font-normal text-2xl">3</div>
                    </button>
                    <button
                      className="rectangle text-center shadow-md flex items-center justify-center"
                      type="button"
                      onClick={() =>
                        dispatch({ type: 'SET_BEDROOM', value: 4 })
                      }
                    >
                      <div className="font-normal text-2xl">4 or more</div>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {form.step === 3 && (
              <div className="text-center">
                <h1 className="step-title">
                  {form.type === 'Sell'
                    ? 'What do you expect your house will sell for?'
                    : 'What is your budget range?'}
                </h1>
                <p className="mb-8 text-white p-2">
                  Agents specialize in different price ranges.
                </p>
                {group === 'RENT' ? (
                  <div className="flex justify-center flex-wrap">
                    <div>
                      <button
                        className="rectangle text-center shadow-md flex items-center justify-center"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_PRICE_RANGE',
                            value: 'RANGE_LESS_THAN_5K',
                          })
                        }
                      >
                        <div className="font-normal text-md">
                          Less than $5000
                        </div>
                      </button>
                      <button
                        className="rectangle text-center shadow-md flex items-center justify-center"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_PRICE_RANGE',
                            value: 'RANGE_5K_TO_10K',
                          })
                        }
                      >
                        <div className="font-normal text-md">
                          $5000 to $10,000
                        </div>
                      </button>
                      <button
                        className="rectangle text-center shadow-md flex items-center justify-center"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_PRICE_RANGE',
                            value: 'RANGE_10K_TO_15K',
                          })
                        }
                      >
                        <div className="font-normal text-md">
                          $10,000 to $15,000
                        </div>
                      </button>
                      <button
                        className="rectangle text-center shadow-md flex items-center justify-center"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_PRICE_RANGE',
                            value: 'RANGE_15K_OR_MORE',
                          })
                        }
                      >
                        <div className="font-normal text-md">
                          $15,000 or more
                        </div>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center flex-wrap">
                    <div>
                      <button
                        className="rectangle text-center shadow-md flex items-center justify-center"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_PRICE_RANGE',
                            value: 'RANGE_LESS_THAN_500K',
                          })
                        }
                      >
                        <div className="font-normal text-md">
                          Less than $500k
                        </div>
                      </button>
                      <button
                        className="rectangle text-center shadow-md flex items-center justify-center"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_PRICE_RANGE',
                            value: 'RANGE_500K_TO_1M',
                          })
                        }
                      >
                        <div className="font-normal text-md">$500k to $1m</div>
                      </button>
                      <button
                        className="rectangle text-center shadow-md flex items-center justify-center"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_PRICE_RANGE',
                            value: 'RANGE_1M_TO_2M',
                          })
                        }
                      >
                        <div className="font-normal text-md">$1m to $2m</div>
                      </button>
                      <button
                        className="rectangle text-center shadow-md flex items-center justify-center"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_PRICE_RANGE',
                            value: 'RANGE_2M_OR_MORE',
                          })
                        }
                      >
                        <div className="font-normal text-md">$2m or more</div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {form.step === 4 && (
              <>
                {form.isCountingAgents ? (
                  <div className="flex items-center justify-center">
                    <span className="text-6xl text-white">Searching</span>
                    <div className="mt-12">
                      <LoadingDots />
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <h1 className="step-title">
                      It&apos;s most important that my agent ...
                    </h1>
                    <p className="mb-8 text-white p-2">
                      We&apos;ll match you with an agent who has satisfied
                      similar clients in the past.
                    </p>
                    <div className="flex justify-center flex-wrap">
                      <button
                        className="box text-center shadow-md flex items-center justify-center p-2"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_DESCRIPTION',
                            value: 'Takes care of everything',
                          })
                        }
                      >
                        <div className="font-normal text-xl">
                          Takes care of everything
                        </div>
                      </button>
                      <button
                        className="box text-center shadow-md flex items-center justify-center p-2"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_DESCRIPTION',
                            value: 'Gets me the best price',
                          })
                        }
                      >
                        <div className="font-normal text-xl">
                          Gets me the best price
                        </div>
                      </button>
                      <button
                        className="box text-center shadow-md flex items-center justify-center p-2"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_DESCRIPTION',
                            value: 'Charges low commission',
                          })
                        }
                      >
                        <div className="font-normal text-xl">
                          Charges low commission
                        </div>
                      </button>
                      <button
                        className="box text-center shadow-md flex items-center justify-center p-2"
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: 'SET_DESCRIPTION',
                            value: 'Can sell quickly',
                          })
                        }
                      >
                        <div className="font-normal text-xl">
                          Can sell quickly
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
            {form.step === 5 && (
              <div className="flex justify-center flex-wrap">
                <div>
                  <div className="text-center">
                    <h1 className="step-title">
                      Almost there! How can we get in touch with you?
                    </h1>
                    <p className="mb-8 text-white">
                      There are{' '}
                      {form.agentCount && form.agentCount.toLocaleString()} top
                      Realty agent perfectly matching your needs. We can get our
                      area specialist to contact you asap.
                    </p>
                  </div>
                  <Row type="flex" justify="center" align="middle">
                    <Col xs={24} sm={12}>
                      <div className="support-card mx-4">
                        <div>
                          <p className="mb-8 text-white px-8 text-center">
                            Our Realty team will follow up to make sure
                            everything is ok.
                          </p>
                        </div>
                        <div
                          className="text-center"
                          style={{ filter: 'grayscale()' }}
                        >
                          <div className="inline-block mx-6">
                            <Avatar size={80} src={lizel} />
                          </div>
                          <div className="inline-block mx-6">
                            <Avatar size={80} src={troy} />
                          </div>
                          <div className="inline-block mx-6">
                            <Avatar size={80} src={irish} />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={12}>
                      <div className="flex items-center justify-center flex-wrap w-full mt-4 sm:mt-0">
                        {form.submit ? (
                          <div className="p-4 text-center text-white">
                            <span className="text-lg font-semibold">
                              Thank you for your enquiry! You will be notified
                              very soon.
                            </span>
                          </div>
                        ) : (
                          <ContactAgentInformationForm
                            type={form.type}
                            status={form.status}
                            priceRange={form.priceRange}
                            locationData={
                              form.locationValue.length
                                ? form.locationValue
                                : null
                            }
                            bedroom={form.bedroom}
                            agentId={form.agentId}
                            onChange={handleSubmit}
                            goal={form.goal}
                            street={form.street}
                            streetNumber={form.streetNumber}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            <div className="flex justify-center my-10">
              <div className="w-1/2">
                <Progress
                  percent={form.submit ? 100 : computeProgress()}
                  showInfo
                />
              </div>
            </div>
          </Col>
          <Link to={`/`} className="text-white text-lg mt-8">
            <DoubleLeftOutlined /> Back to Search
          </Link>
        </Row>
      </div>
    </div>
  );
}

export default ContactAgentPage;
