export const buyRange = [
  50000,
  75000,
  100000,
  125000,
  150000,
  175000,
  200000,
  225000,
  250000,
  275000,
  300000,
  325000,
  350000,
  375000,
  400000,
  425000,
  450000,
  475000,
  500000,
  550000,
  600000,
  650000,
  700000,
  750000,
  800000,
  850000,
  900000,
  950000,
  1000000,
  1100000,
  1200000,
  1300000,
  1400000,
  1500000,
  1600000,
  1700000,
  1800000,
  1900000,
  2000000,
  2250000,
  2500000,
  2750000,
  3000000,
  3500000,
  4000000,
  4500000,
  5000000,
  6000000,
  7000000,
  8000000,
  9000000,
  10000000,
  12000000,
  15000000,
];

export const rentRange = [
  50,
  75,
  100,
  125,
  150,
  175,
  200,
  225,
  250,
  275,
  300,
  325,
  350,
  375,
  400,
  425,
  450,
  475,
  500,
  525,
  550,
  575,
  600,
  625,
  650,
  675,
  700,
  725,
  750,
  800,
  850,
  900,
  950,
  1000,
  1100,
  1200,
  1300,
  1400,
  1500,
  1600,
  1700,
  1800,
  1900,
  2000,
  2500,
  3000,
  3500,
  4000,
  4500,
  5000,
];

export const landAreaRange = [
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  1000,
  1200,
  1500,
  1750,
  2000,
  3000,
  4000,
  5000,
  10000,
  20000,
  30000,
  40000,
  50000,
  100000,
  200000,
  300000,
  400000,
  500000,
  600000,
  700000,
  800000,
  900000,
];

export default buyRange;
