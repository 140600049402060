import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const SearchStateContext = React.createContext();
const SearchDispatchContext = React.createContext();

const SEARCH_UPDATE = 'update';

function reducer(state, { type, ...params }) {
  switch (type) {
    case SEARCH_UPDATE:
    default:
      return { ...state, ...params };
  }
}

function SearchProvider({ children, value = {} }) {
  const [data, dispatch] = useReducer(reducer, value);

  return (
    <SearchStateContext.Provider value={data}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
}

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
};

function useSearchState() {
  const context = React.useContext(SearchStateContext);
  if (context === undefined) {
    throw new Error('useSearchState must be used within a SearchProvider');
  }
  return context;
}

function useSearchUpdater() {
  const context = React.useContext(SearchDispatchContext);
  if (context === undefined) {
    throw new Error('useSearchUpdater must be used within a SearchProvider');
  }
  const setSearch = React.useCallback(debounce(context, 350), []);
  return setSearch;
}

export { SEARCH_UPDATE, SearchProvider, useSearchState, useSearchUpdater };
