import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { Button, Row } from 'antd';
import emptySearch from './search.png';
import Loading from '../Loading';
import Error from '../Error';
import PropertyListItem from '../PropertyListItem';
import LoadingDots from '../LoadingDots';

const PropertiesConnectionQuery = loader(
  '../../queries/PropertiesConnectionQuery.graphql'
);

function PropertyList({ searchOptions, data, locationsData, agencyId }) {
  const [loadingMore, setLoadingMore] = useState(false);

  const { propertiesConnection, loading, error, fetchMore } = data;

  const handleLoadMore = async () => {
    const {
      keywords,
      price,
      parking,
      landArea,
      bedroom,
      mapLocation,
      bathroom,
      ofiTime,
      group,
      isIncludeSurrounding,
      sorting,
      agencyId,
    } = searchOptions;

    setLoadingMore(true);

    return new Promise(async resolve => {
      try {
        await fetchMore({
          variables: {
            datesFrom: ofiTime,
            filter: {
              agencyId,
              bathroom,
              bed: bedroom,
              first: 12,
              garage: parking,
              group,
              isIncludeSurrounding,
              sorting,
              keyword: keywords,
              landArea,
              location:
                locationsData && locationsData.length > 0
                  ? locationsData.map(({ id }) => id)
                  : [],
              mapLocation,
              ofiTime,
              price,
              after: propertiesConnection.pageInfo.endCursor,
            },
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const { nodes } = fetchMoreResult.propertiesConnection;

            return nodes.length
              ? {
                  ...previousResult,
                  propertiesConnection: {
                    ...fetchMoreResult.propertiesConnection,
                    nodes: [
                      ...previousResult.propertiesConnection.nodes,
                      ...nodes,
                    ],
                  },
                }
              : previousResult;
          },
        });
        resolve(true);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingMore(false);
      }
    });
  };

  if (loading) {
    return (
      <div className="h-screen relative">
        <Loading className="center-align" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="pt-12 ">
        <Error className="w-1/2" error={error} />
      </div>
    );
  }

  const showLoadingMore = propertiesConnection
    ? propertiesConnection.pageInfo.hasNextPage
    : false;
  const dataSource = propertiesConnection ? propertiesConnection.nodes : [];

  if (dataSource.length === 0) {
    return (
      <div
        className="p-6 rounded bg-white text-center relative mt-10"
        style={{ height: 'calc(100vh - 208px)' }}
      >
        <div className="center-align">
          <img src={emptySearch} alt="empty search results" className="w-5/6" />
          <h2 className="font-thin mt-2 text-gray-700">
            <div className="ml-6">
              <p className="text-4xl my-4">Sorry, no property results found</p>
            </div>
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 md:px-0">
      <Row type="flex" align="middle" justify="center" gutter={12}>
        {dataSource.map(property => (
          <PropertyListItem
            key={property.slugUrl}
            property={property}
            agencyId={agencyId}
          />
        ))}
      </Row>
      {showLoadingMore ? (
        <>
          {loadingMore ? (
            <div className="p-12 flex justify-center">
              <LoadingDots />
            </div>
          ) : (
            <div className="mt-4">
              <Button
                size="large"
                block
                onClick={e => {
                  e.preventDefault();
                  handleLoadMore();
                }}
              >
                More Properties
              </Button>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

PropertyList.propTypes = {
  searchOptions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  agencyId: PropTypes.string,
  locationsData: PropTypes.array,
};

const PropertyListWithData = graphql(PropertiesConnectionQuery, {
  options: ({
    locationsData,
    searchOptions: {
      keywords,
      price,
      parking,
      landArea,
      bedroom,
      mapLocation,
      bathroom,
      ofiTime,
      group,
      isIncludeSurrounding,
      category,
      sorting,
      agencyId,
    },
  }) => ({
    variables: {
      datesFrom: ofiTime,
      filter: {
        agencyId,
        bathroom,
        bed: bedroom,
        first: 12,
        garage: parking,
        group,
        isIncludeSurrounding,
        keyword: keywords,
        landArea,
        location:
          locationsData && locationsData.length > 0
            ? locationsData.map(({ id }) => id)
            : [],
        mapLocation,
        ofiTime,
        price,
        category,
        sorting,
      },
    },
  }),
})(PropertyList);

export default PropertyListWithData;
