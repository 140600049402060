import { Select } from 'antd';
import React, { Component } from 'react';
import { buyRange, rentRange } from '../../constant/range';
import PropTypes from 'prop-types';
import numeral from 'numeral';

const { Option } = Select;

class PriceFilterRange extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    defaultValue: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: [0, 0],
    defaultValue: [50000, 5000000],
    onChange: () => {},
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.value[0] !== state.valueMin ||
      props.value[1] !== state.valueMax
    ) {
      return {
        valueMin:
          props.value && props.value[0]
            ? props.value[0]
            : props.defaultValue[0],
        valueMax:
          props.value && props.value[1]
            ? props.value[1]
            : props.defaultValue[1],
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { value, defaultValue } = this.props;

    this.state = {
      valueMin: value && value[0] ? value[0] : defaultValue[0],
      valueMax: value && value[1] ? value[1] : defaultValue[1],
    };
  }

  handleChange = (valueMin, valueMax) => {
    const { onChange } = this.props;
    if (valueMin > valueMax) {
      this.setState(
        {
          valueMin: valueMin,
          valueMax: this.props.defaultValue[1],
        },
        () => {
          onChange(valueMin, this.props.defaultValue[1]);
          let text = 'min > max';
          console.log(text, valueMin, this.props.defaultValue[1]);
        }
      );
    } else {
      this.setState(
        {
          valueMin: valueMin,
          valueMax: valueMax,
        },
        () => {
          onChange(valueMin, valueMax);
          let text = 'max > min';
          console.log(text, valueMin, valueMax);
        }
      );
    }
  };

  handleMinChange = valueMin => {
    this.handleChange(valueMin, this.state.valueMax);
  };

  handleMaxChange = valueMax => {
    this.handleChange(this.state.valueMin, valueMax);
  };

  render() {
    const { type } = this.props;
    const range = type === 'rent' ? rentRange : buyRange;

    const buyPriceRange = range.map(bPrice => {
      return (
        <Option value={bPrice} key={bPrice}>
          {numeral(bPrice).format('($0[.]00a)')}
        </Option>
      );
    });

    return (
      <div>
        <Select
          id="min-price"
          value={
            this.props.min === this.state.valueMin
              ? undefined
              : this.state.valueMin
          }
          showSearch
          style={{ width: 200 }}
          placeholder="Min price"
          optionFilterProp="children"
          onChange={this.handleMinChange}
        >
          {buyPriceRange}
        </Select>
        <Select
          id="max-price"
          value={
            this.props.max === this.state.valueMax
              ? undefined
              : this.state.valueMax
          }
          showSearch
          style={{ width: 200 }}
          placeholder="Max price"
          optionFilterProp="children"
          onChange={this.handleMaxChange}
        >
          {buyPriceRange}
        </Select>
      </div>
    );
  }
}

export default PriceFilterRange;
