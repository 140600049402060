import React, { Component } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import './PropertyAudio.scss';

class PropertyAudio extends Component {
  static propTypes = {
    showModal: PropTypes.bool,
    audio: PropTypes.string,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    showModal: false,
    audio: null,
    onClose: () => {},
  };

  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    const { showModal, audio } = this.props;
    return (
      <Modal
        visible={showModal}
        destroyOnClose
        onCancel={this.handleCancel}
        footer={null}
        width={null}
        className="property-modal-audio"
      >
        <audio src={audio} controls className="center-align" />
      </Modal>
    );
  }
}

export default PropertyAudio;
