import React, { PureComponent } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import './Buttons.scss';

export const CarouselPrevArrow = props => {
  const { className, onClick } = props;
  return (
    <FA
      icon={faChevronLeft}
      onClick={onClick}
      className={`arrow prev ${className}`}
    />
  );
};

CarouselPrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CarouselPrevArrow.defaultProps = {
  className: '',
  onClick: () => {},
};

export const CarouselNextArrow = props => {
  const { className, onClick } = props;
  return (
    <FA
      icon={faChevronRight}
      onClick={onClick}
      className={`arrow next ${className}`}
    />
  );
};

CarouselNextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CarouselNextArrow.defaultProps = {
  className: '',
  onClick: () => {},
};

export const OfiButton = props => {
  const { showOfiModal } = props;
  return (
    <Button
      type="primary"
      onClick={showOfiModal}
      className="rounded"
      size="large"
    >
      <div className="flex items-center">
        <ClockCircleOutlined className="h-auto md:text-base text-2xl" />
      </div>
    </Button>
  );
};

OfiButton.propTypes = { showOfiModal: PropTypes.func };
OfiButton.defaultProps = { showOfiModal: () => {} };

class SaveButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    text: PropTypes.objectOf(PropTypes.string),
  };

  static defaultProps = {
    className: '',
    loading: false,
    isSuccess: undefined,
    text: {
      loading: 'Saving',
      success: 'Saved',
      default: 'Save',
    },
  };

  state = { isSuccess: false, prevLoading: false };

  componentDidUpdate = prevProps => {
    const { isSuccess } = this.props;

    if (prevProps.loading) {
      this.setState({ isSuccess, prevLoading: prevProps.loading });
      setTimeout(() => {
        this.setState({ isSuccess: false, prevLoading: false });
      }, 1000);
    }
  };

  render() {
    const { isSuccess, className, loading, text, ...rest } = this.props;
    const { isSuccess: success, prevLoading } = this.state;

    return (
      <Button
        {...rest}
        loading={loading}
        className={`SaveButton font-bold transition ${className} ${prevLoading &&
          ((success === false && 'failed') || 'success')}`}
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
          className={`transition ${success && 'success'} check`}
        >
          <circle
            className="path circle"
            fill="none"
            stroke="#73AF55"
            strokeWidth="6"
            strokeMiterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <polyline
            className="path check"
            fill="none"
            stroke="#73AF55"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
          className={`transition ${(prevLoading &&
            success === false &&
            'failed') ||
            'hide-item'} times`}
        >
          <circle
            className="path circle"
            fill="none"
            stroke="#D06079"
            strokeWidth="6"
            strokeMiterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <line
            className="path line"
            fill="none"
            stroke="#D06079"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="34.4"
            y1="37.9"
            x2="95.8"
            y2="92.3"
          />
          <line
            className="path line"
            fill="none"
            stroke="#D06079"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="95.8"
            y1="38"
            x2="34.4"
            y2="92.2"
          />
        </svg>
        {loading
          ? text.loading
          : (success && text.success) ||
            (success === false && prevLoading && 'Failed') ||
            text.default}
      </Button>
    );
  }
}

export { SaveButton };
