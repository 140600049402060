import React, { useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { useApolloClient } from '@apollo/client';
import debounce from 'lodash/debounce';
import { loader } from 'graphql.macro';

const LocationSuggestionsQuery = loader(
  '../../queries/LocationSuggestionsQuery.gql'
);

function extractCodes(values) {
  return values
    ? values.map(item => ({
        key: `${item.id}:#${item.full}`,
        label: item.full,
      }))
    : [];
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case 'SET_RESULT':
      return {
        ...state,
        dataSource: action.dataSource,
        isLoading: false,
      };
    case 'SET_SELECTED':
      return {
        ...state,
        selected: action.selected,
      };
    default:
      throw new Error();
  }
}

function PropertySuburbSelect({
  placeholder = 'Search suburb',
  size = 'large',
  value,
  disabled,
  onChange,
}) {
  const client = useApolloClient();

  const initialState = {
    isLoading: false,
    dataSource: [],
    selected: extractCodes(value),
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const suggestLocations = async keyword => {
    dispatch({ type: 'SET_LOADING', isLoading: true });

    try {
      const {
        data: { locationSuggestions },
      } = await client.query({
        query: LocationSuggestionsQuery,
        variables: {
          filter: {
            keyword,
            first: 10,
            type: 'SUBURB',
          },
        },
      });
      dispatch({ type: 'SET_RESULT', dataSource: locationSuggestions || [] });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = useCallback(debounce(suggestLocations, 500), []);

  const triggerChange = changedValue => {
    onChange(changedValue);
  };

  const handleChange = currentValue => {
    if (currentValue) {
      const newValue = [currentValue];

      const selectedData =
        newValue &&
        newValue.map(({ key }) => {
          const split = key.split(':#');
          return {
            id: split[0],
            full: split[1],
          };
        });

      if (value !== undefined) {
        dispatch({ type: 'SET_SELECTED', selected: selectedData });
      }
      triggerChange(selectedData);
    } else {
      triggerChange(null);
    }
  };

  const renderOptions = (options = []) => {
    const filteredResult = options.filter(item => item !== null);

    return filteredResult.map(item => {
      const { full } = item;
      return (
        <Select.Option
          key={full}
          className="show-all py-3"
          value={`${item.id}:#${full}`}
        >
          <span className="text-base">{full}</span>
        </Select.Option>
      );
    });
  };

  return (
    <Select
      allowClear
      size={size}
      className="keyword-input"
      placeholder={placeholder}
      notFoundContent={
        state.isLoading ? <Spin size="small" /> : 'No results found'
      }
      filterOption={false}
      onChange={handleChange}
      labelInValue
      onSearch={handleSearch}
      style={{ width: '100%' }}
      value={state.selected}
      showSearch
      disabled={!disabled}
      getPopupContainer={trigger => trigger.parentNode}
      id="location"
    >
      {renderOptions(state.dataSource)}
    </Select>
  );
}

PropertySuburbSelect.propTypes = {
  value: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
};

export default PropertySuburbSelect;
