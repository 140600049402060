import React, { Component } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import './PropertyViewTour.scss';

class PropertyViewTour extends Component {
  static propTypes = {
    showModal: PropTypes.bool,
    tour: PropTypes.string,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    showModal: false,
    tour: null,
    onClose: () => {},
  };

  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    const { showModal, tour } = this.props;
    const tourUrl = tour.replace(/^http:\/\//i, 'https://');
    return (
      <Modal
        visible={showModal}
        destroyOnClose
        onCancel={this.handleCancel}
        footer={null}
        width={null}
        className="property-modal-tour"
      >
        <iframe
          width="100%"
          height="500px"
          src={tourUrl}
          title="Property Tour"
        />
      </Modal>
    );
  }
}

export default PropertyViewTour;
