import React from 'react';
import PropTypes from 'prop-types';
import { PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Carousel } from 'antd';
import { getColorShade } from '../../utils/colors';
import AgentContactNumber from '../AgentContactNumber';
import './AgencyAgentCarousel.scss';

function AgencyAgentCarousel({
  agents = [],
  agency,
  propertyId,
  onClickEnquire = () => {},
}) {
  if (!agents.length) {
    return null;
  }

  const { bannerColor } = agency || {};
  const isWhiteAccent = bannerColor && bannerColor.startsWith('#fff');
  const shade =
    !isWhiteAccent && bannerColor !== null && getColorShade(bannerColor)
      ? 'dark'
      : 'light';

  return (
    <div
      className="contact-agent relative bg-gray-200 text-gray-700"
      style={{ backgroundColor: bannerColor || '#fff' }}
    >
      <Carousel effect="fade" dots={false} autoplay>
        {agents.map(agent => (
          <div key={agent.id}>
            <div className="flex items-center p-2">
              <div className="mr-2">
                <Avatar
                  alt={`${agent.name}`}
                  size={64}
                  icon={<UserOutlined />}
                  src={agent.profilePicture}
                  className="shadow border-2 border-white bg-grey-300"
                />
              </div>
              <div>
                <span className={`block font-medium ${shade}`}>
                  {agent.firstName} {agent.lastName}
                </span>
                {agent.contactNo && (
                  <span className="block text-blue-500">
                    <a
                      href={`tel:${agent.contactNo}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PhoneOutlined className="mr-2" />
                    </a>
                    <AgentContactNumber
                      agentId={agent.id}
                      contactNo={agent.contactNo}
                      propertyId={propertyId}
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="flex justify-center m-2">
              <Button
                className="text-overflow"
                onClick={() => {
                  onClickEnquire(agent);
                }}
              >
                Enquire with {agent.firstName}
              </Button>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

AgencyAgentCarousel.propTypes = {
  agents: PropTypes.array,
  agency: PropTypes.object,
  propertyId: PropTypes.string,
  onClickEnquire: PropTypes.func,
};

export default AgencyAgentCarousel;
