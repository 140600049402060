import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';

import {
  EnvironmentOutlined,
  LeftOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Button, Col, Form, Input, message, Modal, Radio, Row } from 'antd';
import { getColorShade } from '../../utils/colors';
import { trackAnalytics } from '../../utils/analytics';
import './AgencyEnquiryForm.scss';

const AGENCY_ENQUIRY = gql`
  mutation CreateAgencyEnquiryMutation($input: CreateAgencyEnquiryInput!) {
    createAgencyEnquiry(input: $input) {
      id
      name
      email
      message
      enquiries
      contactNumber
    }
  }
`;

const AgencyEnquiryForm = memo(props => {
  const { bannerColor, agencyId } = props;
  const [form] = Form.useForm();
  const { getFieldValue, resetFields } = form;
  const [chosen, setChosen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [next, setNext] = useState(false);
  const [formErrorCode, setFormErrorCode] = useState(null);

  const [createAgencyEnquiry] = useMutation(AGENCY_ENQUIRY);
  const getErrorMessage = () => {
    let errorMessage = null;
    switch (formErrorCode) {
      case 'SERVER_ERROR':
        errorMessage = 'There was a problem sending your enquiry.';
        break;
      default:
        errorMessage = 'Unable to send enquiry.';
        break;
    }
    return errorMessage;
  };

  const handleSubmit = values => {
    trackAnalytics({
      category: 'Agency Enquiry',
      action: 'Submit Agency Enquiry',
      agencyId,
    });

    setIsLoading(true);

    const transformedValues = {
      ...values,
      agencyId,
      enquiries: chosen,
    };
    createAgencyEnquiry({
      variables: {
        input: transformedValues,
      },
    })
      .then(() => {
        Modal.success({
          title: 'Enquiry Successfully Sent',
          onOk: () => {
            setChosen(null);
            resetFields();
          },
        });
      })
      .catch(() => {
        message.error('Encountered an Error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChange = ({ target: { value } }) => {
    setChosen(value);
  };

  const back = () => {
    setChosen(null);
    setNext(false);
  };

  const handleNext = () => {
    setNext(true);
  };

  const isNotWhiteColor = bannerColor && bannerColor !== '#fff';

  const buttonStyle = {
    background: (isNotWhiteColor && bannerColor) || '#3b82f6',
    color: isNotWhiteColor && getColorShade(bannerColor) ? '#fff' : '#3D4852',
  };

  return (
    <Form
      onFinish={handleSubmit}
      className="AgencyEnquiryForm"
      form={form}
      initialValues={{
        enquiries: getFieldValue('enquiries') ? onChange : null,
      }}
    >
      <Row type="flex" justify="center" align="middle" className="w-full">
        <Col xs={22} sm={22} md={20} lg={20} xl={20} xxl={20}>
          {!next && (
            <>
              <Form.Item
                className="w-full"
                name="enquiries"
                rules={[{ required: true }]}
              >
                <Radio.Group onChange={onChange} className="w-full">
                  <Radio.Button
                    value="General enquiry"
                    className="w-full mb-2 choices"
                  >
                    <span className="sm:text-base text-sm">
                      General enquiry
                    </span>
                  </Radio.Button>
                  <br />
                  <Radio.Button
                    value="Property appraisal"
                    className="w-full mb-2 choices"
                  >
                    <span className="sm:text-base text-sm">
                      Property appraisal
                    </span>
                  </Radio.Button>
                  <br />
                  <Radio.Button
                    value="Selling a property"
                    className="w-full mb-2 choices"
                  >
                    <span className="sm:text-base text-sm">
                      Selling a property
                    </span>
                  </Radio.Button>
                  <br />
                  <Radio.Button
                    value="Property management"
                    className="w-full mb-2 choices"
                  >
                    <span className="sm:text-base text-sm">
                      Property management
                    </span>
                  </Radio.Button>
                  <br />
                  <Radio.Button
                    value="I'd like more information"
                    className="w-full mb-2 choices"
                  >
                    <span className="sm:text-base text-sm">
                      I&apos;d like more information
                    </span>
                  </Radio.Button>
                  <br />
                  <Radio.Button value="Other" className="w-full mb-2 choices">
                    <span className="sm:text-base text-sm">Other</span>
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Button
                  size="large"
                  htmlType="submit"
                  className="w-full poppins text-overflow uppercase text-sm"
                  disabled={!getFieldValue('enquiries')}
                  style={{ ...buttonStyle }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Form.Item>
            </>
          )}
          {next && (
            <>
              <h2 className="font-semibold text-grey-darker mb-4 bg-white py-1 px-4 rounded-full border border-grey-light text-base">
                {chosen}
              </h2>
              <span className="text-grey-darker text-xs uppercase font-semibold py-1 inline-block">
                Name
              </span>
              <Form.Item
                className="w-full"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Your name is required',
                  },
                ]}
              >
                <Input
                  className="w-full"
                  size="large"
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
              </Form.Item>
              {chosen !== 'General enquiry' &&
                chosen !== 'Other' &&
                chosen !== "I'd like more information" && (
                  <>
                    <span className="text-grey-darker text-xs uppercase font-semibold py-1 inline-block">
                      Property Address
                    </span>
                    <Form.Item className="w-full" name="propertyAddress">
                      <Input
                        className="w-full"
                        size="large"
                        prefix={
                          <EnvironmentOutlined
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                      />
                    </Form.Item>
                  </>
                )}
              <span className="text-grey-darker text-xs uppercase font-semibold py-1 inline-block">
                Email
              </span>
              <Form.Item
                className="w-full"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Your email is required',
                  },
                ]}
              >
                <Input
                  className="w-full"
                  size="large"
                  prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
              </Form.Item>
              <span className="text-grey-darker text-xs uppercase font-semibold py-1 inline-block">
                Phone
              </span>
              <Form.Item className="w-full" name="contactNumber">
                <Input
                  className="w-full"
                  size="large"
                  prefix={
                    <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                />
              </Form.Item>
              <span className="text-grey-darker text-xs uppercase font-semibold py-1 inline-block">
                Message
              </span>
              <Form.Item
                className="w-full"
                name="message"
                rules={[
                  {
                    required: true,
                    message: 'Your message is required',
                  },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <div className="flex flex-row flex-no-wrap">
                <Button
                  size="large"
                  className="mb-4 flex-1 mr-1 uppercase text-sm"
                  onClick={() => back()}
                  style={{ ...buttonStyle }}
                >
                  <LeftOutlined className="text-xs font-bold" />
                  &nbsp;Back
                </Button>
                <Button
                  size="large"
                  htmlType="submit"
                  className="poppins text-overflow w-3/4 uppercase text-sm"
                  loading={isLoading}
                  style={{ ...buttonStyle }}
                >
                  Send Enquiry
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
});

AgencyEnquiryForm.propTypes = {
  agencyId: PropTypes.string,
  bannerColor: PropTypes.string,
};

AgencyEnquiryForm.defaultProps = {
  agencyId: null,
  bannerColor: '',
};

export default AgencyEnquiryForm;
