import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import './styles.scss';

const { Item: MenuItem } = Menu;

function MainMenu({ location }) {
  const currentPage = [];
  switch (location.pathname) {
    case '/contact-agent':
      currentPage.push('/contact-agent');
      break;
    case '/':
    default:
      currentPage.push('/');
      break;
  }
  return (
    <Menu className="main-menu" selectedKeys={currentPage} mode="horizontal">
      <MenuItem key="/">
        <Link to="/">Properties</Link>
      </MenuItem>
      <MenuItem key="/contact-agent">
        <Link to="/contact-agent">Find Agent</Link>
      </MenuItem>
    </Menu>
  );
}

MainMenu.propTypes = {
  location: PropTypes.object,
};

export default withRouter(MainMenu);
