import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import {
  faBath,
  faBed,
  faDollarSign,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import './styles.scss';
import PriceFilterRangeFormItem from '../PriceFilterRangeFormItem';
import BedFilterFormItem from '../BedFilterFormItem';
import BathFilterFormItem from '../BathFilterFormItem';
import {
  SEARCH_UPDATE,
  useSearchState,
  useSearchUpdater,
} from '../../contexts/search-context';

function AdvancedSearch(props) {
  const { searchOptions, setSearchOptions } = props;
  const { price, group } = searchOptions;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      price: {
        min: price ? price.min : 50000,
        max: price ? price.max : 5000000,
      },
    });
  }, [group]);

  const onValuesChange = (changedValues, values) => {
    const { price, bedroom, bathroom } = values;

    let priceFilter = {
      min: undefined,
      max: undefined,
    };

    if (`${group}`.toUpperCase() === 'RENT') {
      priceFilter = {
        max: price ? (price.max === 2000 ? undefined : price.max) : undefined,
        min: price ? (price.min === 10 ? undefined : price.min) : undefined,
      };
    } else {
      priceFilter = {
        max: price
          ? price.max === 5000000
            ? undefined
            : price.max
          : undefined,
        min: price ? (price.min === 50000 ? undefined : price.min) : undefined,
      };
    }

    const newSearchOptions = {
      ...values,
      price: priceFilter,
      bed: {
        max: bedroom ? bedroom.max : undefined,
        min: bedroom
          ? bedroom.min === 0
            ? undefined
            : bedroom.min
          : undefined,
      },
      bathroom: {
        max: bathroom.max,
        min: bathroom
          ? bathroom.min === 0
            ? undefined
            : bathroom.min
          : undefined,
      },
      group,
    };

    setSearchOptions({ type: SEARCH_UPDATE, ...newSearchOptions });
  };
  return (
    <div className="advanced-search my-2 px-4 md:px-0">
      <Form
        form={form}
        initialValues={{
          price: {
            min: price ? price.min : 50000,
            max: price ? price.max : 5000000,
          },
          bedroom: {
            min: 0,
            max: 6,
          },
          bathroom: {
            min: 0,
            max: 6,
          },
        }}
        onValuesChange={onValuesChange}
      >
        <Row className="filters-container" type="flex" gutter={8}>
          <Col xs={24} md={14} className="mb-2 md:mb-0">
            <div className="filter-item flex flex-no-wrap items-center p-2">
              <div className="flex flex-col items-center mr-2 text-blue-700">
                <FA icon={faDollarSign} className="text-lg" />
                <span className="mb-0 font-normal text-xs">Price</span>
              </div>
              <div className="w-full">
                <Form.Item name="price" noStyle>
                  <PriceFilterRangeFormItem
                    miniInfo={false}
                    type={`${group}`.toLowerCase()}
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={12} md={5}>
            <div className="filter-item flex flex-no-wrap items-center p-2">
              <div className="flex flex-col items-center mr-2 text-blue-700">
                <FA icon={faBed} className="text-lg" />
                <span className="mb-0 font-normal text-xs">Bed</span>
              </div>
              <div className="w-full">
                <Form.Item name="bedroom" noStyle>
                  <BedFilterFormItem />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={12} md={5}>
            <div className="filter-item flex flex-no-wrap items-center p-2">
              <div className="flex flex-col items-center mr-2 text-blue-700">
                <FA icon={faBath} className="text-lg" />
                <span className="mb-0 font-normal text-xs">Bath</span>
              </div>
              <div className="w-full">
                <Form.Item name="bathroom" noStyle>
                  <BathFilterFormItem />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

AdvancedSearch.propTypes = {
  searchOptions: PropTypes.object.isRequired,
  setSearchOptions: PropTypes.func,
};

export default props => {
  const search = useSearchState();
  const setSearch = useSearchUpdater();
  return (
    <AdvancedSearch
      {...props}
      searchOptions={search}
      setSearchOptions={setSearch}
    />
  );
};
