/* eslint-disable array-callback-return */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import { CalendarOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row, Tooltip } from 'antd';
import moment from 'moment';
import Download from '@axetroy/react-download';
import './OfiTime.scss';
import { trackAnalytics } from '../../utils/analytics';

class OfiTime extends Component {
  static propTypes = {
    address: PropTypes.string,
    ofiTime: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
    open: PropTypes.bool,
    street: PropTypes.string,
    toggleForm: PropTypes.func,
    property: PropTypes.object,
  };

  static defaultProps = {
    address: '',
    ofiTime: {},
    open: false,
    street: '',
    toggleForm: () => {},
    property: {},
  };

  state = { showMore: false };

  handleClick = () => {
    const { showMore } = this.state;
    this.setState({ showMore: !showMore });
  };

  snakeCase = text =>
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '_') // Replace spaces with _
      /* eslint-disable no-useless-escape */
      .replace(/[^\w\-]+/g, '_') // Replace _ all non-word chars
      /* eslint-disable no-useless-escape */
      .replace(/\_\_+/g, '_') // Replace multiple _ with single -
      .replace(/^_+/, '') // Trim _ from start of text
      .replace(/_+$/, ''); // Trim _ from end of text

  trackSavedInspectionTimes = () => {
    const { property } = this.props;
    const { id, agents, agency } = property;

    agents.map(agent => {
      trackAnalytics({
        category: 'Property Inspection Times',
        action: 'Save Property Inspection Times',
        propertyId: id,
        agencyId: agency.id,
        agentId: agent.id,
      });
    });
  };

  render() {
    const { ofiTime, street, address, open, toggleForm } = this.props;
    const { showMore } = this.state;
    const showClass = showMore ? 'show-item py-2' : 'hide-item';
    const showText = showMore ? 'Show Less' : 'Show More';
    const ofiTimes = ofiTime.filter(
      time =>
        time.datesFrom &&
        time.datesTo &&
        moment().isSameOrBefore(
          moment(time.datesFrom) ||
            moment().isSameOrBefore(moment(time.datesTo))
        )
    );

    const ofi = street
      ? `${this.snakeCase(street)}.ics`
      : `${this.snakeCase(address)}.ics`;

    return (
      <div className="property-time my-2 xs:p-2 shadow rounded-lg w-full">
        <Collapse
          className="time-collapse"
          bordered={false}
          onChange={() => toggleForm()}
          activeKey={!open ? '1' : '0'}
        >
          <Collapse.Panel
            header={
              <span className="uppercase text-grey-darkest font-bold text-center block">
                Open for Inspection Times
              </span>
            }
            key="1"
            className="bg-grey-lighter rounded-lg"
          >
            <div className="overflow-y-auto times-cont">
              {ofiTimes.length > 0 ? (
                ofiTimes.map(({ id, datesFrom, datesTo, icsData }, index) => {
                  const border =
                    index + 1 !== ofiTimes.length &&
                    'border-b border-grey-light';
                  const show = index > 2 ? `${showClass}` : 'py-2';

                  return (
                    <Row
                      className={`ofi-entry ${border} ${show}`}
                      key={id}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <Col xs={3}>
                        <h4 className="uppercase text-center font-light text-grey-darker">
                          {moment.utc(datesFrom).format('ddd')}
                        </h4>
                        <h2 className="text-center text-blue-dark leading-tight">
                          {moment.utc(datesFrom).format('D')}
                        </h2>
                        <h4 className="uppercase text-center font-light text-grey-darker">
                          {moment.utc(datesFrom).format('MMM')}
                        </h4>
                      </Col>
                      <Col xs={18}>
                        <div className="text-center text-lg">
                          {`
                                                    ${moment
                                                      .utc(datesFrom)
                                                      .format('h:mm a')} -
                                                    ${moment
                                                      .utc(datesTo)
                                                      .format('h:mm a')}
                                                `}
                        </div>
                      </Col>
                      <Col xs={3}>
                        <Download file={ofi || 'ofi.ics'} content={icsData}>
                          <Tooltip title="Save to calendar">
                            <CalendarOutlined
                              style={{ cursor: 'pointer' }}
                              className="hover:text-blue-dark text-2xl"
                              onClick={this.trackSavedInspectionTimes}
                            />
                          </Tooltip>
                        </Download>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <p className="text-center">
                  <span className="block">
                    No inspections are currently scheduled.
                  </span>
                  <span className="font-bold text-blue">
                    {' '}
                    Contact the agent
                  </span>{' '}
                  to arrange an appointment.
                </p>
              )}
              {ofiTime.length > 3 && (
                <div className="text-center pt-2">
                  <Button onClick={this.handleClick}>{showText}</Button>
                </div>
              )}
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}

export default withApollo(OfiTime);
