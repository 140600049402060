import React from 'react';
import { Row } from 'antd';
import './Footer.scss';
import poweredBy from '../../assets/poweredby-white.png';

const Footer = () => (
  <div className="footer">
    <Row
      type="flex"
      key="company"
      justify="center"
      className="bg-gray-800 w-full p-2"
    >
      <a
        href="https://www.realty.com.au/facebook"
        className="ml-3"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={poweredBy} alt="poweredby-realty.com.au" className="h-12" />
      </a>
    </Row>
  </div>
);
export default Footer;
