import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import qs from 'query-string';
import './styles.scss';
import { SearchProvider, useSearchState } from '../../contexts/search-context';
import SearchPage from '../../pages/SearchPage';
import PropertyPage from '../../pages/PropertyPage';
import ContactAgentPage from '../../pages/ContactAgentPage';
import GoogleAnalyticsTracker from '../GoogleAnalyticsTracker';
import AboutPage from '../../pages/AboutPage';

function App({ location }) {
  const query = qs.parse(location.search);
  const { agencyId, locationFull } = query;

  const searchState = {
    group: 'BUY',
    isIncludeSurrounding: agencyId ? false : true,
    agencyId,
    locationFull,
    keyword: locationFull
      ? { key: locationFull, value: locationFull }
      : undefined,
  };

  return (
    <GoogleAnalyticsTracker location={location}>
      <SearchProvider value={searchState}>
        <Switch>
          <Route exact path="/contact-agent" component={ContactAgentPage} />
          {/*<Route exact path="/property/:slugUrl" component={PropertyPage}/>*/}
          <Route exact path="/property/:slugUrl" render={(props) => <PropertyPage {...props} agencyId={agencyId}/>} />
          <Route exact path="/about" component={AboutPage} />
          <Route path="/" component={SearchPage} />
        </Switch>
      </SearchProvider>
    </GoogleAnalyticsTracker>
  );
}

App.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(App);
