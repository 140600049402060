import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FacebookShareButton } from 'react-share';
import './styles.scss';
import BackgroundImage from '../BackgroundImage';
import PropertyPrice from '../PropertyPrice';
import PropertyAddress from '../PropertyAddress';
import MainFeatures from '../MainFeatures';
import defaultPropertyImage from '../../assets/default-property-image.svg';

function PropertyCard({ property }) {
  const { objects, isUnderOffer, priceView, status, features } = property;
  const mainImageUrl = objects.length ? objects[0].url : undefined;
  const shareLink = `${process.env.REACT_APP_PUBLIC_URL}/property/${property.slugUrl}`;
  return (
    <div className="property-card">
      <div className="primary-content">
        <div className="media">
          <BackgroundImage
            src={mainImageUrl || defaultPropertyImage}
            aspectRatio="3:2"
          />
        </div>
        <div className="card-body">
          <div className="flex items-center w-full">
            {isUnderOffer && (
              <div className="p-1 mr-1 bg-blue-100 text-xs text-blue-800 font-semibold rounded">
                Under Offer
              </div>
            )}
            <div className="flex-1 text-overflow text-lg text-gray-900 font-semibold truncate">
              {status === 'SOLD' || !priceView ? (
                <PropertyPrice property={property} />
              ) : (
                priceView
              )}
            </div>
          </div>
          <div className="text-sm font-semibold mb-2 text-gray-700 block">
            <PropertyAddress property={property} newLine />
          </div>
          <div className="mt-3">
            <MainFeatures
              features={features}
              classNames={{
                feature:
                  'rounded-full font-semibold1 mr-3 text-gray-600 property-feature text-sm',
              }}
            />
          </div>
          <div
            className="flex justify-end text-2xl text-gray-500"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <FacebookShareButton url={shareLink}>
              <FA icon={faFacebook} />
            </FacebookShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}

PropertyCard.propTypes = {
  property: PropTypes.object.isRequired,
};

export default PropertyCard;
