import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Error from '../Error';
import Loading from '../Loading';
import AgencyEnquiryForm from '../AgencyEnquiryForm';

const AgencyQuery = loader('./AgencyQuery.gql');

function AgencyEnquiry(props) {
  const {
    data: { agency, loading, error },
  } = props;

  if (loading) return <Loading className="full-page" />;
  if (error) return <Error error={error} />;
  if (!agency) return '';

  const { name, id, bannerColor } = agency;

  return (
    <>
      <Row justify="center" type="flex" className="py-8" id="enquiry">
        <Col xs={22} lg={18} xl={14} xxl={10}>
          <div className="text-center lg:py-6 md:py-2 py-4">
            <p className="md:text-2xl text-lg text-center font-semibold">
              Get in touch with&nbsp;
              {name}
            </p>
          </div>
          <AgencyEnquiryForm agencyId={id} bannerColor={bannerColor} />
        </Col>
      </Row>
    </>
  );
}

AgencyEnquiry.propTypes = {
  data: PropTypes.shape({
    agency: PropTypes.object,
    error: PropTypes.bool,
    loading: PropTypes.bool,
  }),
};

const AgencyEnquiryWithData = graphql(AgencyQuery, {
  options: ({ id }) => ({ variables: { id } }),
})(AgencyEnquiry);

export default AgencyEnquiryWithData;
