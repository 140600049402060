import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import LoadingDots from '../LoadingDots';

const LocationByFullQuery = loader('./Operations.graphql');

const LocationResolver = React.memo(props => {
  const { locations, children } = props;

  const { loading, data } = useQuery(LocationByFullQuery, {
    skip: !locations,
    variables: { locations },
  });

  if (!(locations && locations.length > 0)) {
    return children(null);
  }

  if (loading) {
    return (
      <div className="py-4">
        <LoadingDots />
      </div>
    );
  }

  return children(data ? data.locationsByFull : []);
});

LocationResolver.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.any.isRequired,
};

LocationResolver.defaultProps = { locations: [] };

export default LocationResolver;
