import React, { memo } from 'react';
import { Card, Col, Divider, Input, Row, Tooltip, Form } from 'antd';
import PropTypes from 'prop-types';
import priceParse from 'price-parse';
import {
  faCalendar,
  faDollarSign,
  faPercent,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import NumericInput from '../PriceInput';
import './PropertyHomeLoanCalculator.scss';
import { formatPriceWithTwoDecimalPoints } from '../../utils/number';

const PropertyHomeLoanCalculator = memo(props => {
  const {
    property: { priceView },
  } = props;
  const [form] = Form.useForm();
  const { getFieldValue } = form;

  const calculator = () => {
    const loanAmount = getFieldValue('loanAmount') || 1;

    let loanTerm = getFieldValue('loanTerm') || 1;
    let interestRate = getFieldValue('interestRate');

    loanTerm *= 12;
    if (interestRate === '0' || interestRate === '') {
      interestRate = 0.01;
    }
    interestRate = interestRate / 12 / 100;

    return (
      (loanAmount * interestRate) / (1 - 1 / (1 + interestRate) ** loanTerm) ||
      0.0
    );
  };

  const checkNumeric = (rule, value, callback) => {
    if (value > 0) {
      callback();
      return;
    }
    callback('Input a number greater than zero!');
  };

  const checkRate = (rule, value, callback) => {
    if (value > 100) {
      callback('Interest rate max value is 100!');
      return;
    }
    if (value !== '' && !/^-?\d+(\.?\d+)?$/.test(value)) {
      callback('Enter a valid rate value!');
      return;
    }

    if (value > 0) {
      callback();
      return;
    }
    callback();
  };

  const extractPriceFromPriceView = priceView => {
    let loanPrice = null;
    const price = priceParse(priceView);

    if (!isNaN(price)) {
      loanPrice = price;
    }

    return loanPrice;
  };

  const price = extractPriceFromPriceView(priceView);

  return (
    <>
      <Divider />
      <Form
        className="my-4"
        form={form}
        initialValues={{
          loanAmount: price || 0,
          loanTerm: 30,
          interestRate: 3.64,
        }}
      >
        <div className="mb-2">
          <h3 className="md:text-2xl text-xl">Home Loan Calculator</h3>
        </div>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="w-full"
          gutter={16}
        >
          <Col xs={24} sm={12} md={12} lg={12}>
            <span className="montserrat capitalize text-grey-darker pr-10">
              Loan amount
            </span>
            <Form.Item
              className="w-full mb-0"
              name="loanAmount"
              rules={[{ validator: checkNumeric }]}
            >
              <NumericInput
                className="w-full"
                prefix={<FA icon={faDollarSign} className="text-blue" />}
                size="large"
                placeholder="Loan Amount"
              />
            </Form.Item>
            <span className="montserrat capitalize text-grey-darker pr-2">
              Loan term <span className="lowercase text-grey">(year/s)</span>
            </span>
            <Form.Item
              className="w-full mt-4 mb-0"
              name="loanTerm"
              rules={[{ validator: checkNumeric }]}
            >
              <NumericInput
                prefix={<FA icon={faCalendar} className="text-blue" />}
                placeholder="Loan Term*"
                size="large"
              />
            </Form.Item>
            <span className="montserrat capitalize text-grey-darker">
              Interest rate (p.a.)
            </span>
            <Form.Item
              className="w-full mb-0"
              name="interestRate"
              getValueFromEvent={e =>
                e.target.value.replace(/[^0-9.]+/g, '').replace(/^[.]/g, '0.')
              }
              rules={[{ validator: checkRate }]}
            >
              <Input
                prefix={<FA icon={faPercent} className="text-blue" />}
                placeholder="Interest Rate (Yearly)"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className="mt-4">
            <Card
              bordered={false}
              className="text-right monthly-payment-card my-2 xs:p-2 shadow"
            >
              <span className="text-3xl text-blue-dark font-medium">
                <span className="text-base" style={{ marginRight: 2 }}>
                  <Tooltip title="Australian Dollar">$</Tooltip>
                </span>
                {formatPriceWithTwoDecimalPoints(calculator())}
              </span>
              <p className="text-xs text-right uppercase">Monthly payment</p>
            </Card>
          </Col>
        </Row>
      </Form>
      <Divider className="mt-8" />
    </>
  );
});

PropertyHomeLoanCalculator.propTypes = {
  onClose: PropTypes.func,
  property: PropTypes.object,
  propertyId: PropTypes.string,
};

PropertyHomeLoanCalculator.defaultProps = {
  onClose: () => {},
  property: {},
  propertyId: null,
};

export default PropertyHomeLoanCalculator;
