export const formatPriceWithTwoDecimalPoints = price => {
  const priceData = price || 0;

  return priceData.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const formatPrice = price => {
  const priceData = price || 0;

  return priceData.toLocaleString('en-US', { maximumFractionDigits: 0 });
};
