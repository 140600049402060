import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { onError } from '@apollo/client/link/error';

export default () => {
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_API });

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) console.log(`[Network error]: ${networkError}`);
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions, message, locations, path }) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(
            `[GraphQL error]: Message: ${message}, Code: ${
              extensions ? extensions.code : ''
            }, Location: ${locations}, Path: ${path}`
          );
        }
      });
    }
  });

  const cache = new InMemoryCache({
    addTypename: true,
    logger: console.log,
    loggerEnabled: true,
  });

  const client = new ApolloClient({
    link: from([
      onErrorLink,
      createPersistedQueryLink({ useGETForHashedQueries: true }),
      httpLink,
    ]),
    cache,
    name: 'web-portal',
    version: '1.0',
  });
  return client;
};
