/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import './PropertyEnquiryForm.scss';
import { gql, useMutation } from '@apollo/client';
import { trackAnalytics } from '../../utils/analytics';

const CreatePropertyEnquiryMutation = gql`
  mutation CreatePropertyEnquiryMutation($input: CreatePropertyEnquiryInput!) {
    createPropertyEnquiry(input: $input) {
      id
      name
      email
      message
      enquiries
      contactNumber
    }
  }
`;

const PropertyEnquiryForm = ({ propertyId, agent, agency }) => {
  const [form] = Form.useForm();
  const { resetFields } = form;
  const [isLoading, setIsLoading] = useState(false);
  const [formErrorCode, setFormErrorCode] = useState(null);

  const [createPropertyEnquiry] = useMutation(CreatePropertyEnquiryMutation);
  const getErrorMessage = email => {
    let errorMessage = null;
    switch (formErrorCode) {
      case 'EMAIL_EXIST':
        errorMessage = `Email ${email} already exists.`;
        break;
      case 'SERVER_ERROR':
        errorMessage = 'There was a problem saving an agency.';
        break;
      default:
        errorMessage = 'Unable to Create account.';
        break;
    }
    return errorMessage;
  };

  const handleSubmit = values => {
    trackAnalytics({
      category: 'Property Enquiry',
      action: 'Submit Property Enquiry',
      propertyId,
      agencyId: agency.id,
      agentId: agent.id,
    });

    setIsLoading(true);

    const transformedValues = {
      ...values,
      propertyId,
      agentId: agent.id,
      referrer: 'www.facebook.com',
    };

    createPropertyEnquiry({
      variables: {
        input: transformedValues,
      },
    })
      .then(() => {
        Modal.success({
          title: 'Enquiry Successfully Sent',
          onOk: () => resetFields(),
        });
      })
      .catch(() => {
        // message.error('Encountered an Error');
        setFormErrorCode(formErrorCode);
        message.destroy();
        message.error(getErrorMessage());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      initialValues={{
        enquiries: [],
        message: 'Hi, Please send me more information about the property.',
      }}
    >
      <Row
        type="flex"
        justify="space-between"
        align="top"
        className="w-full PropertyEnquiryForm"
      >
        <span className="ml-4 text-white text-xs uppercase font-semibold py-1 inline-block">
          My Name
        </span>
        <Form.Item
          className="w-full"
          name="name"
          rules={[
            {
              required: true,
              message: 'Your name is required',
            },
          ]}
        >
          <Input
            className="w-full"
            prefix={<UserOutlined className="text-blue" />}
          />
        </Form.Item>
        <div className="flex flex-wrap w-full">
          <div className="xl:w-1/2 lg:w-full md:w-1/2 w-full">
            <span className="ml-4 text-white text-xs uppercase font-semibold py-1 inline-block">
              Email
            </span>
            <Form.Item
              className="w-full"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid email',
                },
                {
                  required: true,
                  message: 'Your email is required',
                },
              ]}
            >
              <Input prefix={<MailOutlined className="text-blue" />} />
            </Form.Item>
          </div>
          <div className="xl:w-1/2 lg:w-full md:w-1/2 w-full xl:pl-1 lg:pl-0 pl-1">
            <span className="ml-4 text-white text-xs uppercase font-semibold py-1 inline-block">
              Phone Number{' '}
              <span className="text-grey-dark hidden">(optional)</span>
            </span>
            <Form.Item className="w-full" name="contactNumber">
              <Input prefix={<PhoneOutlined className="text-blue" />} />
            </Form.Item>
          </div>
        </div>
        <span className="ml-4 text-white text-xs uppercase font-semibold py-1 inline-block">
          My Enquiries
        </span>
        <Form.Item className="w-full" name="enquiries">
          <Select mode="multiple" style={{ width: '100%' }}>
            <Select.Option key="PRICE_GUIDE">Price Guide</Select.Option>
            <Select.Option key="OFI">Inspection Times</Select.Option>
            <Select.Option key="PROPERTY_SIZE">Property Size</Select.Option>
            <Select.Option key="CONTRACT_SALE">Contract of Sale</Select.Option>
            <Select.Option key="SIMILAR_PROPERTIES">
              Similar Properties
            </Select.Option>
          </Select>
        </Form.Item>
        <span className="ml-4 text-white text-xs uppercase font-semibold inline-block mb-2">
          Your Message
        </span>
        <Form.Item className="w-full" name="message">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="w-full poppins text-overflow bg-red-light border-red-light hover:bg-red-dark hover:border-red-dark rounded-lg"
          loading={isLoading}
        >
          Enquire Now
        </Button>
      </Form.Item>
    </Form>
  );
};

PropertyEnquiryForm.propTypes = {
  agent: PropTypes.object,
  agency: PropTypes.object,
  propertyId: PropTypes.string,
};

PropertyEnquiryForm.defaultProps = {
  agent: {},
  agency: {},
  propertyId: null,
};

export default PropertyEnquiryForm;
