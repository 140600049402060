import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberPlusMinusInput from '../NumberPlusMinusInput';

class BedFilterFormItem extends Component {
  static propTypes = { onChange: PropTypes.func };

  static defaultProps = { onChange: () => {} };

  static getDerivedStateFromProps(props, state) {
    if (props.value.min !== state.value) {
      return { value: props.value.min };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { value } = this.props;

    this.state = { value: value.min };
  }

  handleChange = value => {
    const { onChange } = this.props;
    this.setState({ value }, () => {
      onChange({
        min: value,
        max: undefined,
      });
    });
  };

  render() {
    const { value } = this.state;

    return <NumberPlusMinusInput value={value} onChange={this.handleChange} />;
  }
}

export default BedFilterFormItem;
