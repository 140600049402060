import React, { Component } from 'react';
import { object, func, string } from 'prop-types';
import { EnvironmentFilled } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import { Link } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import debounce from 'lodash/debounce';
import Select from 'rc-select';
import 'rc-select/assets/index.css';
import './styles.scss';

const LocationConnectionsQuery = loader('./LocationConnectionsQuery.gql');

class AgencyLocationList extends Component {
  static propTypes = {
    client: object,
    group: string,
    onChangeLocation: func,
    onChangeGroup: func,
  };

  static defaultProps = {
    onChangeLocation: () => {},
    onChangeGroup: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
    this.handleSearch = debounce(this.handleSearch, 500);
  }

  handleSearch = async value => {
    const { client, agencyId } = this.props;

    const keyword = value;

    this.setState({
      isLoading: true,
      currentKeyword: value,
    });

    try {
      const {
        data: { locationsConnection },
      } = await client.query({
        query: LocationConnectionsQuery,
        variables: {
          id: agencyId,
          filter: {
            keyword,
            first: 20,
            agencyId,
          },
        },
      });
      this.setState({
        isLoading: false,
        dataSource: locationsConnection || [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderOptions = (dataSource, keyword) => {
    const { locationsConnection, isPropertyPage, agencyId } = this.props;

    // if (dataSource.length === 0 && keyword) {
    //   return [
    //     <Select.Option
    //       key={keyword}
    //       className="show-all py-3"
    //       value={`k:${keyword}`}
    //       title={keyword}
    //     >
    //       <span className="text-base location-item">{keyword}</span>
    //     </Select.Option>,
    //   ];
    // }
    const initialData =
      dataSource.length === 0 ? locationsConnection : dataSource;
    const agencySuburb = initialData ? initialData.nodes : [];

    return (
      agencySuburb.length > 0 && (
        <Select.OptGroup
          key="suburbs"
          label={<span className="text-xs">Suburbs</span>}
        >
          {agencySuburb.length &&
            agencySuburb.map(
              ({ full, id }) =>
                !!full && (
                  <Select.Option
                    value={full}
                    key={id}
                    className="capitalize show-all py-3"
                  >
                    {isPropertyPage ? (
                      <Link
                        className="text-green"
                        to={`/?agencyId=${agencyId}`}
                      >
                        <div className="flex items-center">
                          <div className="mr-1">
                            <EnvironmentFilled
                              className=" text-xl"
                              style={{ color: '#666' }}
                            />
                          </div>
                          <div className="text-gray-600 hover:text-white">
                            {full}
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div className="flex items-center">
                        <div className="mr-1">
                          <EnvironmentFilled className=" text-xl" />
                        </div>
                        <div>{full}</div>
                      </div>
                    )}
                  </Select.Option>
                )
            )}
        </Select.OptGroup>
      )
    );
  };

  render = () => {
    const {
      value,
      onChangeLocation,
      locationsConnection,
      buttonsOnly,
      onChangeGroup,
      group,
    } = this.props;
    const agencySuburb = locationsConnection ? locationsConnection.nodes : [];
    const { dataSource, currentKeyword } = this.state;

    return (
      <div className="agency-location-list w-full">
        {/*{buttonsOnly ? (*/}
        {/*<div className="flex flex-wrap justify-center mt-3">*/}
        {/*{agencySuburb.length > 0 && agencySuburb.map(*/}
        {/*({ full, id }) =>*/}
        {/*<Button*/}
        {/*type="primary"*/}
        {/*key={id}*/}
        {/*className="cursor-pointer flex-initial mr-2 mb-2 w-1/6"*/}
        {/*onClick={()=>{ onChangeLocation({ key: full, label: full }); }}*/}
        {/*style={{ whiteSpace: 'pre-line', height: 'auto' }}*/}
        {/*>*/}
        {/*{full}*/}
        {/*</Button>*/}
        {/*)}*/}
        {/*</div>*/}
        {/*) : (*/}
        <div className="flex agency-location-filter">
          <Select
            className="w-1/6"
            defaultValue="BUY"
            optionLabelProp="children"
            onChange={onChangeGroup}
            value={group}
          >
            <Select.Option value="BUY">Buy</Select.Option>
            <Select.Option value="RENT">Rent</Select.Option>
          </Select>
          <Select
            className="flex-1"
            labelInValue
            placeholder="Search by suburb"
            animation="slide-up"
            allowClear
            onSearch={this.handleSearch}
            filterOption={false}
            onChange={onChangeLocation}
            value={value}
            showArrow={false}
          >
            {this.renderOptions(dataSource, currentKeyword)}
          </Select>
        </div>
        {/*)}*/}
      </div>
    );
  };
}

export default withApollo(AgencyLocationList);
