import React, { memo } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import NearbySchools from '../NearbySchools';
import './PropertyNearbySchoolsList.scss';

const PropertyNearbySchoolsList = memo(({ propertyId }) => {
  return (
    <div className="PropertyNearbySchoolsList">
      <div className="mt-4">
        <h3 className="md:text-2xl text-xl">Nearby Schools</h3>
      </div>
      <div className="overflow-x-auto w-full">
        <Tabs defaultActiveKey="All">
          <Tabs.TabPane tab="All" key="All">
            <NearbySchools propertyId={propertyId} type="All" />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Primary" key="Primary">
            <NearbySchools propertyId={propertyId} type="Primary" />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Secondary" key="Secondary">
            <NearbySchools propertyId={propertyId} type="Secondary" />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Special" key="Special">
            <NearbySchools propertyId={propertyId} type="Special" />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
});

PropertyNearbySchoolsList.propTypes = { propertyId: PropTypes.string };

PropertyNearbySchoolsList.defaultProps = { propertyId: '' };

export default PropertyNearbySchoolsList;
