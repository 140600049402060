import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';

class NumberPlusMinusInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.number,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    label: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    defaultValue: 0,
    value: 0,
    min: 0,
    max: 10,
    label: '',
    className: 'text-lg mx-2 font-medium',
  };

  constructor(props) {
    super(props);
    const { value, defaultValue } = this.props;

    this.state = { value: value === undefined ? defaultValue : value };
  }

  onClickMinus = () => {
    this.setState(
      ({ value }, { min }) => ({ value: value > min ? value - 1 : value }),
      () => {
        this.triggerChange(this.state.value);
      }
    );
  };

  onClickAdd = () => {
    this.setState(
      ({ value }, { max }) => ({ value: value < max ? value + 1 : value }),
      () => {
        this.triggerChange(this.state.value);
      }
    );
  };

  triggerChange = value => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    //   const { value } = this.state;
    const { label, min, max, value: propsValue, className, size } = this.props;

    return (
      <span className="number-plus-minus-input inline-block">
        <Button
          disabled={!(propsValue > min)}
          type="primary"
          shape="circle"
          icon={<MinusOutlined />}
          ghost
          size={size}
          className="button-minus"
          onClick={this.onClickMinus}
        />
        <span className={className}>
          <span className="mr-1 inline-block">{propsValue}+</span>
          {label}
        </span>
        <Button
          disabled={!(propsValue < max)}
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          ghost
          size={size}
          className="button-plus"
          onClick={this.onClickAdd}
        />
      </span>
    );
  }
}

export default NumberPlusMinusInput;
