import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Logo from './Logo';
import './Loading.scss';

class Loading extends PureComponent {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
  };

  static defaultProps = {
    height: 100,
    width: 100,
    className: '',
  };

  render() {
    const { height, width, className } = this.props;

    return (
      <div
        className={`flex items-center justify-center text-center p-8 loading-container ${className}`}
      >
        <div className="block mr-2 rounded-full h-auto w-auto p-2">
          <Logo height={height} width={width} />
        </div>
      </div>
    );
  }
}

export default Loading;
