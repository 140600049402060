import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { withRouter } from 'react-router-dom';
import PropertyCard from '../PropertyCard';

function PropertyListItem({ property, history, agencyId }) {
  const handleClick = () => {
    history.push({
      pathname: `/property/${property.slugUrl}`,
      search: `?agencyId=${agencyId}`,
    });
  };

  const handleKeyDown = event => {
    if (event.keycode === 13) {
      handleClick(event);
    }
  };

  return (
    <Col xs={24} md={8} className="mt-4">
      <div
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="link"
        tabIndex="0"
        className="w-full h-full"
      >
        <PropertyCard property={property} />
      </div>
    </Col>
  );
}

PropertyListItem.propTypes = {
  property: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(PropertyListItem);
