import React, { Component } from 'react';
import {
  GoogleMap,
  Marker,
  OverlayView,
  StreetViewPanorama,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import PropTypes from 'prop-types';
import MapStyleSilver from '../../constant/mapStyle';
import marker from './marker-blue.png';

class PropertyMap extends Component {
  static propTypes = {
    isMarkerShown: PropTypes.bool,
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleMapView: PropTypes.func,
    isStreetView: PropTypes.bool,
  };

  static defaultProps = {
    isMarkerShown: false,
    isStreetView: true,
    lat: '',
    lng: '',
    handleMapView: () => {},
  };

  state = {
    hasPanoramaLocation: false,
    visibleChanged: false,
  };

  componentDidMount() {
    // https://stackoverflow.com/questions/8945283/google-maps-streetviewpanorama-display-error
    const { handleMapView, lat, lng } = this.props;
    const { visibleChanged } = this.state;
    const location = new window.google.maps.LatLng(lat, lng);

    const sv = new window.google.maps.StreetViewService();

    sv.getPanoramaByLocation(location, 50, (data, status) => {
      if (status === 'OK') {
        this.setState({ hasPanoramaLocation: true });
      } else {
        this.setState({ hasPanoramaLocation: false });
      }
    });

    this.listener = window.google.maps.event.addListener(
      this.map.getStreetView(),
      'visible_changed',
      function mapVisible() {
        if (this.getVisible !== visibleChanged) {
          handleMapView(this.getVisible());
        }
      }
    );
  }

  componentWillUnmount() {
    window.google.maps.event.removeListener(this.listener);
  }

  render() {
    const { isMarkerShown, lat, lng, isStreetView } = this.props;
    const { hasPanoramaLocation } = this.state;
    const getPixelPositionOffset = (width, height) => ({
      x: -(width / 2),
      y: -(height / 2),
    });

    return (
      <GoogleMap
        ref={map => {
          this.map = map;
        }}
        defaultZoom={15}
        defaultCenter={{
          lat,
          lng,
        }}
        options={{ styles: MapStyleSilver }}
      >
        {isMarkerShown && (
          <Marker
            position={{
              lat,
              lng,
            }}
            icon={marker}
          />
        )}
        {isStreetView && hasPanoramaLocation && (
          <StreetViewPanorama
            defaultPosition={{
              lat,
              lng,
            }}
            visible
          >
            <OverlayView
              position={{
                lat,
                lng,
              }}
              mapPaneName={OverlayView.OVERLAY_LAYER}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <div
                style={{
                  background: 'red',
                  color: 'white',
                  padding: 5,
                  borderRadius: '50%',
                  display: 'none',
                }}
              >
                OverlayView
              </div>
            </OverlayView>
          </StreetViewPanorama>
        )}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(PropertyMap));
