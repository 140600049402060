import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PriceFilterRange from '../PriceFilterRange';

class PriceFilterRangeFormItem extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    miniInfo: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    miniInfo: true,
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.value.min !== state.valueMin ||
      props.value.max !== state.valueMax
    ) {
      return {
        valueMin: props.value.min,
        valueMax: props.value.max,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { value } = this.props;

    this.state = {
      valueMin: value.min,
      valueMax: value.max,
    };
  }

  handleChange = (valueMin, valueMax) => {
    const { onChange } = this.props;
    this.setState(
      {
        valueMin,
        valueMax,
      },
      () => {
        onChange({
          min: valueMin,
          max: valueMax,
        });
      }
    );
  };

  render() {
    const { valueMin, valueMax } = this.state;
    const { type } = this.props;

    const rentFilter =
      type === 'rent'
        ? {
            defaultValue: [10, 2000],
            min: 10,
            max: 2000,
          }
        : {
            min: 50000,
            max: 5000000,
          };

    return (
      <PriceFilterRange
        type={type}
        value={[valueMin, valueMax]}
        onChange={this.handleChange}
        {...rentFilter}
      />
    );
  }
}

export default PriceFilterRangeFormItem;
