import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Modal } from 'antd';
import AgencyContactNumber from '../../AgencyContactNumber';

class AgencyContactModal extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool,
    agency: PropTypes.object,
    onOk: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    agency: {},
    onOk: () => {},
  };

  render() {
    const { visible, agency, onOk } = this.props;
    const { name, logoUrl, mobileNumber, landlineNumber } = agency;
    return (
      <Modal
        title={
          <div className="flex justify-center items-center">
            <Avatar
              size={100}
              alt={`${name}`}
              icon={<UserOutlined />}
              src={logoUrl}
              className="shadow border-4 border-white bg-grey-light"
            />
          </div>
        }
        style={{ top: 20 }}
        visible={visible}
        onCancel={onOk}
        footer={[]}
      >
        <div className="flex justify-between text-lg">
          <div>Mobile</div>
          {landlineNumber || mobileNumber ? (
            <AgencyContactNumber
              useTel
              id={agency.id}
              number={landlineNumber || mobileNumber}
            />
          ) : (
            'Not Available'
          )}
        </div>
      </Modal>
    );
  }
}

export default AgencyContactModal;
