import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import {
  useSearchState,
  useSearchUpdater,
  SEARCH_UPDATE,
} from '../../contexts/search-context';
import AgencyLocationList from '../AgencyLocationList';
import { loader } from 'graphql.macro';
import Error from '../Error';
import LoadingDots from '../LoadingDots';

const LocationConnectionsQuery = loader('./LocationConnectionsQuery.gql');

function getLocationFormValue(search) {
  return search.keyword ? search.keyword : undefined;
}

function AgencyLocationFilter(props) {
  const {
    data: { loading, error, locationsConnection },
    agencyId,
    isPropertyPage,
    // buttonsOnly,
  } = props;
  const search = useSearchState();
  const setSearch = useSearchUpdater();

  const handleChangeLocation = keyword => {
    setSearch({ type: SEARCH_UPDATE, keyword });
  };
  const handleChangeGroup = group => {
    setSearch({ type: SEARCH_UPDATE, group });
  };

  if (loading) return <LoadingDots />;
  if (error) return <Error error={error} />;

  return (
    // <div className={`${buttonsOnly ? 'w-full' : 'w-1/2'}`}>
    <div className="w-full sm:w-3/4">
      <AgencyLocationList
        // buttonsOnly={buttonsOnly}
        isPropertyPage={isPropertyPage}
        locationsConnection={locationsConnection || []}
        agencyId={agencyId}
        onChangeGroup={handleChangeGroup}
        onChangeLocation={handleChangeLocation}
        value={getLocationFormValue(search)}
        group={search.group}
      />
    </div>
  );
}

const AgencyLocationFilterWithData = graphql(LocationConnectionsQuery, {
  options: ({ agencyId, buttonsOnly }) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: agencyId,
      filter: {
        first: /* buttonsOnly ? 10 : */ 20,
        agencyId,
      },
    },
  }),
})(AgencyLocationFilter);

export default AgencyLocationFilterWithData;
