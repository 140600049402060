import React from 'react';
import PropTypes from 'prop-types';
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

const CreateAgentLead = loader('./CreateAgentLead.gql');

function ContactAgentInformationForm({
  priceRange,
  type,
  status,
  locationData,
  bedroom,
  agentId,
  goal,
  streetNumber,
  street,
  onChange,
}) {
  const [form] = Form.useForm();
  const [createAgentLead, { loading }] = useMutation(CreateAgentLead);

  const handleSubmit = values => {
    const mutationData = {
      ...values,
      priceRange,
      type,
      status,
      locationId:
        locationData && locationData.length ? locationData[0].id : null,
      bedroom: parseInt(bedroom, 10),
      agentId,
      goal,
      streetNumber,
      street,
    };

    createAgentLead({ variables: mutationData });
    onChange(true);
  };

  return (
    <Form
      className="flex items-center flex-col"
      onFinish={handleSubmit}
      form={form}
    >
      <h3 className="text-lg font-semibold text-white mb-4">
        Leave your details and we&apos;ll be in touch
      </h3>
      <Form.Item
        className="w-full"
        name="fullName"
        rules={[
          {
            required: true,
            message: 'Please input your Full Name!',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Full Name*"
          size="large"
        />
      </Form.Item>
      <Form.Item
        className="w-full"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your Email!',
          },
        ]}
      >
        <Input
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="email"
          placeholder="Email*"
          size="large"
        />
      </Form.Item>
      <Form.Item
        className="w-full"
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: 'Please input your Phone Number!',
          },
        ]}
      >
        <Input
          prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Phone Number*"
          size="large"
        />
      </Form.Item>
      <Form.Item className="w-full">
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button w-full rounded mb-4"
          loading={loading}
        >
          Notify me about recommended agents
        </Button>
      </Form.Item>
    </Form>
  );
}

ContactAgentInformationForm.propTypes = {
  priceRange: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  locationData: PropTypes.array,
  bedroom: PropTypes.number,
  agentId: PropTypes.string,
  goal: PropTypes.string,
  streetNumber: PropTypes.string,
  street: PropTypes.string,
  onChange: PropTypes.func,
};

export default ContactAgentInformationForm;
