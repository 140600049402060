import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { faBath, faBed, faCar } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import './MainFeatures.scss';

const Feature = memo(props => {
  const { classNames, displayText, icon, data, text, displayTextClass } = props;

  return (
    !!data && (
      <div className={`${classNames.feature} inline-block`} title={text}>
        <div className="flex items-center">
          <FA icon={icon} className={classNames.icon} />
          <span className="leading-none">
            &nbsp;
            {data}
          </span>
        </div>
        {displayText && (
          <div className={displayTextClass}>
            {text}
            {data > 1 && 's'}
          </div>
        )}
      </div>
    )
  );
});

Feature.propTypes = {
  classNames: PropTypes.shape({
    icon: PropTypes.string,
    feature: PropTypes.string,
  }),
  displayText: PropTypes.bool,
  displayTextClass: PropTypes.string,
  icon: PropTypes.object,
  data: PropTypes.number,
  text: PropTypes.string,
};

Feature.defaultProps = {
  classNames: {
    icon: '',
    feature:
      'text-gray-600 property-feature border-r border-gray-200 pr-2 py-1 mr-2 inline-block',
  },
  displayTextClass: 'font-semibold uppercase text-xs',
  displayText: false,
  icon: faBed,
  data: 0,
  text: '',
};

const PropertyMainFeatures = memo(props => {
  const { features, classNames, displayText, displayTextClass } = props;
  const { bedrooms, bathrooms, totalParking } = features || {};

  return (
    <div className="MainFeatures leading-none">
      <Feature
        classNames={classNames}
        displayText={displayText}
        icon={faBed}
        data={bedrooms}
        text="bed"
        displayTextClass={displayTextClass}
      />
      <Feature
        classNames={classNames}
        displayText={displayText}
        icon={faBath}
        data={bathrooms}
        text="bath"
        displayTextClass={displayTextClass}
      />
      <Feature
        classNames={classNames}
        displayText={displayText}
        icon={faCar}
        data={totalParking}
        text="parking"
        displayTextClass={displayTextClass}
      />
      {!bedrooms && !bathrooms && !totalParking && (
        <span className="text-transparent property-feature-placeholder" />
      )}
    </div>
  );
});

PropertyMainFeatures.propTypes = {
  classNames: PropTypes.shape({
    icon: PropTypes.string,
    feature: PropTypes.string,
  }),
  displayText: PropTypes.bool,
  displayTextClass: PropTypes.string,
  features: PropTypes.shape({
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    totalParking: PropTypes.number,
  }),
};
PropertyMainFeatures.defaultProps = {
  classNames: {
    icon: '',
    feature:
      'text-gray-600 property-feature border-r border-gray-200 pr-2 py-1 mr-2 inline-block',
  },
  displayTextClass: 'font-semibold uppercase text-xs',
  displayText: false,
  features: {},
};

export default PropertyMainFeatures;
