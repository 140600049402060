import React from 'react';
import PropTypes from 'prop-types';
import './LoadingDots.scss';

const LoadingDots = props => {
  const { className } = props;
  return (
    <div className={`LoadingDots ${className}`}>
      <div className="dot dot-1" />
      <div className="dot dot-2" />
      <div className="dot dot-3" />
    </div>
  );
};

LoadingDots.propTypes = { className: PropTypes.string };

LoadingDots.defaultProps = { className: 'w-full text-center' };

export default LoadingDots;
