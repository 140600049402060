/* eslint-disable react/forbid-prop-types */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Avatar, Button, Carousel, Modal } from 'antd';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import ProgressiveLoading from '../ProgressiveLoading';
import { CarouselNextArrow, CarouselPrevArrow } from '../Buttons';
import './PropertyViewPhotos.scss';
import ScreenWidthValueProvider from '../ScreenWidthValueProvider';
import PropertyEnquiryModal from '../modals/PropertyEnquiryModal';
import { trackAnalytics } from '../../utils/analytics';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FacebookShareButton } from 'react-share';

class PropertyViewPhotos extends Component {
  static propTypes = {
    floorplan: PropTypes.string,
    onClose: PropTypes.func,
    property: PropTypes.object,
    showModal: PropTypes.bool,
  };

  static defaultProps = {
    floorplan: '',
    onClose: () => {},
    property: {},
    showModal: true,
  };

  state = { isShowPropertyEnquiryModal: false };

  static getDerivedStateFromProps(props, state) {
    const {
      showModal,
      property: { id, objects, agents, agency },
      floorplan,
    } = props;

    if (showModal) {
      const sortedObjects = floorplan
        ? orderBy(objects, ['type', 'isMain'], ['asc', 'desc'])
        : orderBy(objects, ['type', 'isMain'], ['desc', 'desc']);

      const objectsIndex = sortedObjects.findIndex(
        ({ type, isMain }) => type === (floorplan || 'IMG') && isMain === true
      );

      if (objectsIndex >= 0) {
        agents.map(agent => {
          trackAnalytics({
            category: 'Property Image View',
            action: 'Property Page Image View',
            propertyId: id,
            agencyId: agency.id,
            agentId: agent.id,
            s3Key: sortedObjects[objectsIndex].s3Key,
            type: sortedObjects[objectsIndex].type,
          });
        });
      }
    }

    return null;
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleCarouselControl);
  };

  componentWillUnmount = () => {
    window.addEventListener('keydown', this.handleCarouselControl);
  };

  handleCarouselControl = e => {
    if (this.carousel && this.carouselNav) {
      if (e.keyCode === 39 || e.code === 'ArrowRight') {
        this.carousel.slick.slickNext();
        this.carouselNav.slick.slickNext();
      } else if (e.keyCode === 37 || e.code === 'ArrowLeft') {
        this.carousel.slick.slickPrev();
        this.carouselNav.slick.slickPrev();
      }
    }
  };

  handleCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  handlePropertyEnquiryModal = (isShowPropertyEnquiryModal, agent = {}) => {
    this.setState({
      isShowPropertyEnquiryModal,
      agent,
    });
  };

  setImageGoogleAnalytics = currentIndex => {
    const {
      floorplan,
      property: { id, objects, agents, agency },
    } = this.props;
    const sortedObjects = floorplan
      ? orderBy(objects, ['type', 'isMain'], ['asc', 'desc'])
      : orderBy(objects, ['type', 'isMain'], ['desc', 'desc']);

    if (sortedObjects[currentIndex]) {
      agents.map(agent => {
        trackAnalytics({
          category: 'Property Image View',
          action: 'Property Page Image View',
          propertyId: id,
          agencyId: agency.id,
          agentId: agent.id,
          s3Key: sortedObjects[currentIndex].s3Key,
          type: sortedObjects[currentIndex].type,
        });
      });
    }
  };

  render() {
    const {
      showModal,
      floorplan,
      property: { id, objects, agents, address, agency, slugUrl },
    } = this.props;

    const shareLink = `${process.env.REACT_APP_PUBLIC_URL}/property/${slugUrl}`;
    const { agent, isShowPropertyEnquiryModal } = this.state;
    const sortedObjects = floorplan
      ? orderBy(objects, ['type', 'isMain'], ['asc', 'desc'])
      : orderBy(objects, ['type', 'isMain'], ['desc', 'desc']);

    const objectsIndex = sortedObjects.findIndex(
      ({ type, isMain }) => type === (floorplan || 'IMG') && isMain === true
    );

    return (
      <>
        <PropertyEnquiryModal
          propertyId={id}
          agent={agent}
          onClose={() => this.handlePropertyEnquiryModal(false)}
          visible={isShowPropertyEnquiryModal}
          agency={agency}
        />
        <Modal
          className="w-full property-photos-modal property-photos"
          width={null}
          visible={showModal}
          destroyOnClose
          onCancel={this.handleCancel}
          footer={null}
          wrapClassName="property-modal-wrapper"
        >
          <Carousel
            dots={false}
            initialSlide={objectsIndex}
            beforeChange={(current, next) => {
              this.setImageGoogleAnalytics(next);
              // this.carouselNav.slick.slickGoTo(next);
            }}
            ref={carousel => {
              this.carousel = carousel;
            }}
            lazyLoad="progressive"
            className="property-modal-carousel"
            arrows
            swipeToSlide
            nextArrow={<CarouselNextArrow />}
            prevArrow={<CarouselPrevArrow />}
          >
            {sortedObjects &&
              sortedObjects.map(({ id, url }) => (
                <div
                  className="z-50 relative property-modal-carousel-item"
                  key={url}
                >
                  <img
                    alt={address}
                    className="property-image lazyload"
                    key={url}
                    src={`${url}?fit=crop&crop=entropy&auto=format&w=700&height=350`}
                  />
                  <div
                    className="text-4xl absolute top-0 text-center text-gray-300 p-4"
                    style={{ left: '10%' }}
                  >
                    <FacebookShareButton url={shareLink}>
                      <FA icon={faFacebook} />
                    </FacebookShareButton>
                  </div>
                </div>
              ))}
            {agents && (
              <div
                key="property-enquiry"
                className="property-enquiry center-align"
              >
                <div className="text-white text-4xl font-semibold flex justify-center align-middle items-center test">
                  <div className="h-auto">
                    <span className="md:text-4xl text-2xl">
                      Love this property?
                    </span>
                    {agents.map(agent => {
                      if (agent) {
                        return (
                          <div
                            className="h-auto text-center p-2 md:flex block items-center"
                            key={agent.id}
                          >
                            <Avatar
                              size={80}
                              icon={<UserOutlined />}
                              src={agent.profilePicture}
                              className="inline-block"
                            />
                            <div className="md:inline-block block ml-2 h-auto md:text-left text-center">
                              <Button
                                size="large"
                                onClick={() =>
                                  this.handlePropertyEnquiryModal(true, agent)
                                }
                                className="block mt-2 enquiry-button"
                                shape="round"
                              >
                                <FA
                                  icon={<LegacyIcon type={faEnvelope} />}
                                  className="mr-2"
                                />
                                Send&nbsp;
                                {agent && agent.firstName
                                  ? agent.firstName
                                  : 'Agent'}
                                &nbsp;a Message
                              </Button>
                              <Button
                                size="large"
                                className="md:block inline-block mt-1 enquiry-button"
                                shape="round"
                              >
                                <a href={`tel:${agent.contactNo}`}>
                                  <FA
                                    icon={<LegacyIcon type={faPhone} />}
                                    className="mr-2"
                                  />
                                  Call&nbsp;
                                  {agent && agent.firstName
                                    ? agent.firstName
                                    : 'Agent'}
                                </a>
                              </Button>
                            </div>
                          </div>
                        );
                      }

                      return '';
                    })}
                  </div>
                </div>
              </div>
            )}
          </Carousel>
          <ScreenWidthValueProvider
            lg={6}
            sm={4}
            xs={4}
            md={6}
            xl={10}
            xxl={12}
          >
            {slidesToShow => (
              <Carousel
                dots={false}
                initialSlide={objectsIndex}
                slidesToScroll={1}
                slidesToShow={slidesToShow}
                beforeChange={(current, next) => {
                  // this.carousel.slick.slickGoTo(next);
                }}
                ref={carousel => {
                  this.carouselNav = carousel;
                }}
                lazyLoad="ondemand"
                className="property-modal-nav"
                focusOnSelect
                swipeToSlide
              >
                {sortedObjects &&
                  sortedObjects.map(({ id, url }) => (
                    <div key={id} className="property-modal-carousel-item">
                      <ProgressiveLoading
                        alt="property"
                        src={`${url}?h=200&auto=format`}
                        thumb={`${url}?fit=crop&crop=entropy&px=16&blur=100&auto=format`}
                      />
                    </div>
                  ))}
                <div className="carousel-pagination pointer">
                  <MailOutlined />
                </div>
              </Carousel>
            )}
          </ScreenWidthValueProvider>
        </Modal>
      </>
    );
  }
}

export default PropertyViewPhotos;
