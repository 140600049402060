import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import LoadingDots from '../LoadingDots';
import Error from '../Error';
import { loader } from 'graphql.macro';
import { Link } from 'react-router-dom';

const AgencyQuery = loader('./Operations.graphql');

class AgencyLogoHeader extends Component {
  render() {
    const {
      data: { loading, error, agency },
    } = this.props;

    if (loading) return <LoadingDots />;

    if (error) return <Error error={error} />;

    return agency ? (
      <div className="agency-logo">
        <Link to={`/about/?agencyId=${agency.id}`}>
          <img
            src={
              agency.miniLogoUrl
                ? `${agency.miniLogoUrl}?w=300&h=75`
                : `${agency.logoUrl}?w=300&h=75`
            }
            alt={agency.name}
            style={{ margin: '0 auto' }}
          />
        </Link>
      </div>
    ) : (
      ''
    );
  }
}

const AgencyLogoHeaderWithData = graphql(AgencyQuery, {
  options: ({ id }) => ({
    variables: { id },
  }),
})(AgencyLogoHeader);

export default AgencyLogoHeaderWithData;
